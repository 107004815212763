import React from 'react'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useEffect,useState,useMemo,useCallback,useRef,useContext,forwardRef } from 'react'
import { URL } from '../../common/api/urls';
import { useAxiosWithAuth } from '../../common/api/hooks/useAxiosWithAuth';
import useAuth from '../../common/hooks/useAuth';
import { USER_ROLE_FIELDS,CHART_RELOAD_TIME, USER_RESOURCES_LIST } from '../../common/utility/constant';
import { CircularProgress ,Button, Tooltip} from '@mui/material';
import { TEXT_MSGS,SNACKBAR_AUTO_HIDE_DURATION_SHORT,MARKET_CLOSE_TIME,MARKET_OPEN_TIME,MIN_TABLE_CONTAINER_WIDTH} from '../../common/utility/constant';
import {Snackbar, Alert,Box,FormControl,InputAdornment,OutlinedInput,InputLabel,IconButton,Select,Slider,MenuItem} from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import {Tab,Tabs} from '@mui/material';
import TICKER_TABLE from './components/ticker_table';
import OptionsFooter from './components/options_footer';
import {OPTIONS_TABLE_CHARTS,GREEK_TABLE_TAB_DATA,GREEKS_TABLE_TARGET_TIME,TABLE_COLORS} from '../../common/utility/table_constants';
import { useWindowSize } from '../../common/hooks/useWindowSize';
import { useOutletContext } from "react-router-dom";
import { ToolbarFixed } from '../../layouts/component.js';
import { Typography, Container,TextField} from '@mui/material'
import {styled, useTheme} from '@mui/material/styles';
import { WATERMARK_CONFIG } from '../../common/utility/constant';
import { UserSettingsContext } from '../../setup/routes-manager/RequireAuth'

import SavePositionDialog from './components/save_position_dialog';

import dayjs from 'dayjs';
import SaveExistingPositionDialog from './components/save_existing_position_dialog';
import ConfirmActionDialog from './components/confirm_dialog';

import { DatePicker} from 'antd';


import { AgGridReact } from 'ag-grid-react';
// import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

import { getColumneDef,StrikeRenderer,CallCellRendrer,PutCellRendrer
  } from '../../common/utility/greeks_columnDefs';

  import '../dashboard/styles/options.scss'
import { processGreekTableData,processGreekPositionData} from '../../common/utility/preProcessor';
import cloneDeep from 'lodash.clonedeep';
import SubscriptionPopup from './components/subscription_popup';
import LoginModal from './components/login_popups';

// import { CallCheckBoxRenderer,PutCheckBoxRenderer } from './components/options_table_renderer';
const gridOptions = {
  suppressColumnVirtualisation:true,
  suppressMovableColumns:true,
  suppressColumnMoveAnimation:true,
  suppressRowVirtualisation:true,
  enableCellTextSelection:true,
  ensureDomOrder:true
}

const frameworkComponents= {
  callCellRendrer:CallCellRendrer,
  putCellRendrer:PutCellRendrer,
  strikeComponent:StrikeRenderer
}

const METADATA_ROW_HEIGHT=46;
const META_DATA_HEADER_HEIGHT=28;
const TAB_CONTAINER_HEIGHT=48;
const MIN_TABLE_HEIGHT=300;

const FooterToolbar=styled(ToolbarFixed)(
  ({theme})=> `
  bottom:0px;
  justify-content:center;
  text-align:center;
  `
)

const NEW_POSITION={key:"New", value:"New",data:{}}
const DateFormat = 'DD-MM-YYYY';
const TimeFormat = 'HH:mm'
const ITEM_HEIGHT = 26;
 const ITEM_PADDING_TOP = 8;
 const MenuProps = {
   PaperProps: {
     style: {
       maxHeight: ITEM_HEIGHT * 10 + ITEM_PADDING_TOP,
       width: 80,
       autoFocus: false
     },
   },
 };

export default function GREEKS_TABLE_NEW() {
  const navigate = useNavigate(); 
  const theme=useTheme();
  const from = "/login";
  const { auth } = useAuth();
  const isEliteUser=(auth.resources.find(el=>el==USER_RESOURCES_LIST.TBT_FEED) && auth.role.find(el=>el==USER_ROLE_FIELDS.PRO))
  const [isBasicUser,setIsBasicUser]=useState((auth.role.find(el=>el==USER_ROLE_FIELDS.PRO))?false:true)
  const { userSettings, setUserSettings } = useContext(UserSettingsContext);
  const [showPopup,setShowPopup]=useState(false);
  const [cancelPosition,responseDataPosition,errorPosition,loadedPosition,resetPosition,executeAPIPosition] = useAxiosWithAuth();
  const [cancelLive,responseDataLive,errorLive,loadedLive,resetLive,executeLive] = useAxiosWithAuth();
  const [cancelTab,responseDataTab,errorTab,loadedTab,resetTab,executeAPITab] = useAxiosWithAuth();
  const [cancelOptions,responseDataGreeks,errorGreeks,loadedGreeks,resetGreeks,executeAPIGreeks] = useAxiosWithAuth();
  const [cancelOptionsTarget,responseDataGreeksTarget,errorGreeksTarget,loadedGreeksTarget,resetGreeksTarget,executeAPIGreeksTarget] = useAxiosWithAuth();
  const [cancelSavePosition,responseDataSavePosition,errorSavePosition,loadedSavePosition,resetSavePosition,executeSaveAPIPosition] = useAxiosWithAuth();
  const [cancelDeletePosition,responseDeletePosition,errorDeletePosition,loadedDeletePosition,resetDeletePosition,executeAPIDeletePosition] = useAxiosWithAuth();
  const [cancelStatus,responseDataStatus,errorStatus,loadedStatus,resetStatus,executeAPIStatus] = useAxiosWithAuth();
  const [gridAPIOptions,setGridAPIOptions]=useState();
  const [columnAPIOptions,setColumnAPIOptions]=useState();
  const [selectedRows,setSelectedRows]=useState({call:undefined,put:undefined});
  const [tickerHeight,setTickerTableHeight]=useState();
  const gridRef = useRef();
  const windowSize=useWindowSize();
  const [startDate, setStartDate] = useState(new Date());
  const [tabState,setTabState]=useState({
    tabData:GREEK_TABLE_TAB_DATA,
    tabLoaded:true,
    selectedInstrument:"NIFTY"
  });
  const {tabData,tabLoaded,selectedInstrument}=tabState;
  const [netProfit,setNetProfit]=useState();
  const [spotLtp,setSpotLtp]=useState();
  const [posTime,setPosTime]=useState();
  const [greeksState,setGreeksState]=useState({
    greeksData:undefined,
    greeksResponse:undefined,
    greeksLoaded:false,
    instrument:undefined,
    isInstrumentLive:false,
    reloadInerval:(auth.role.find(el=>el==USER_ROLE_FIELDS.FAST_DATA))?CHART_RELOAD_TIME.GREEKS_DATA:CHART_RELOAD_TIME.SLOW_DATA,
    pollingInterval:CHART_RELOAD_TIME.POLLING_TIME,
    refreshInterval:-1,
    isPollingAllowed:false,
    tableHeight:0,
    actualHeight:0,
    isCompleteRefresh:true,
    isTradingSessionActive:false,
    isStatusCheckAllowed:false,
    statusCheckPollingInterval:CHART_RELOAD_TIME.POLLING_TIME
  
  });
  const {greeksData,greeksResponse,greeksLoaded,isInstrumentLive,reloadInerval,pollingInterval,refreshInterval,isPollingAllowed,tableHeight,actualHeight,isCompleteRefresh,
    isTradingSessionActive,isStatusCheckAllowed,statusCheckPollingInterval}=greeksState;
  const greeksStateRef=useRef(greeksState);
  const greeksDataRef=useRef(greeksData);
  const greeksResponseRef=useRef(greeksResponse);
  const [apiResponse,setApiResponse]=useState({});
  

  const [positionState,setPositionState]=useState({
    positionData:undefined,
    positionLoaded:false,
    selectedPositionIndex:0
  })  

  const {positionData,positionLoaded,selectedPositionIndex}=positionState;
  const [savePositionState,setSavePositionState]=useState({
    openSaveDialog:false,
    positionName:"",
    targetFlow:false
  })
  const {openSaveDialog,positionName,targetFlow}=savePositionState;

  const [saveExistingPositionState,setSaveExistingPositionState]=useState({
    existingOpenSaveDialog:false,
    existingPositionName:"",
    existingTargetFlow:false
  })
  const {existingOpenSaveDialog,existingPositionName,existingTargetFlow}=saveExistingPositionState;

  const [deletePositionState,setdDletePositionState]=useState({
    openDeleteDialog:false,
    deletePositionName:"",
  })
  const {openDeleteDialog,deletePositionName}=deletePositionState;

  const [targetState,setTargetState]=useState({
    // targetIndex:"",
    // targetDate:dayjs(),
    // targetIV:0,
    targetTime:GREEKS_TABLE_TARGET_TIME[0],
    targetIndex:"",
    targetDate:"",
    targetIV:"",
    isTargetCalculated:false,
    targetValue:undefined
    // targetTime:""
  });

  const {targetIndex,targetDate,targetIV,targetTime,isTargetCalculated,targetValue}=targetState;

  const [timeMinValue,setTimeMinValue]=useState(0);
  const [isDirty,setIsDirty]=useState(false);
  const [showLoginPopup,setShowLoginPopup]=useState(false);
  const [loginMsg,setLoginMsg]=useState(TEXT_MSGS.LOGIN_POPUP_MSG);
 
  useEffect(()=>{
    // console.log("window size=",windowSize);
    // setOptionsState((prevState) => ({
    //   ...prevState,
    //   tableHeight:parseInt((actualHeight/windowSize.zoomLevel).toFixed(1))}));
  },[windowSize])
 
  const [msgState, setMsgState] = useState({
    open: false,
    msg:"" ,
    severity:"info"
  });
  
  const { open, msg, severity } = msgState;

  useEffect(() => {
    document.title = "Greeks Table";
    var link = !document.querySelector("link[rel='canonical']") ? document.querySelector("link[rel='canonical']") : document.createElement('link');
    link.setAttribute('rel', 'canonical');
    link.setAttribute('href', document.location.protocol + '//' + document.location.host + document.location.pathname);
    document.head.appendChild(link);

    var meta = !document.querySelector("meta[name='description']") ? document.querySelector("meta[name='description']") : document.createElement('meta');
    meta.setAttribute('name', 'description');
    meta.setAttribute('content', 'Greeks Table. Open Interest, IV, Price Projection, Options Spread strategy tester. NIFTY. BANKNIFTY. FINNIFTY. MIDCPNIFTY.');
    document.head.appendChild(meta);
  }, []);
  
  const handleCallCheckBoxChange=(data,colID,value)=>{
    console.log("handleCallCheckBoxChange======",data,colID,value);
    let qty;
    if(value=="" || parseInt(value)==0)
      qty=0;
    else 
      qty=parseInt(value)
    let modData=data.data;
    let values=modData.get(colID)  
    values.ce_qty=qty;
    console.log("modfied data =",modData,values,qty,greeksData);
    // let modGreeksData=JSON.stringify(JSON.parse(greeksData));
    let modGreeksData=greeksStateRef.current.greeksData;
    console.log("modGreeksData=",modGreeksData,greeksData,greeksStateRef.current.greeksData)
    let index=modGreeksData.findIndex(item=>item.key==data.key)
    console.log("index and greeksdata at index=",index,modGreeksData[index]);
    modGreeksData[index].data.set(colID,values);
    console.log("Final data=",modGreeksData);
    setGreeksState((prevState) => ({
      ...prevState,
      greeksData:modGreeksData,
      greeksResponse:greeksStateRef.current.greeksResponse,
      isCompleteRefresh:false,
     }));
     setIsDirty(true)
 
}


const handlePutCheckBoxChange=(data,colID,value)=>{
  console.log("handlePutCheckBoxChange=",data,colID,value);
  let qty;
  if(value=="" || parseInt(value)==0)
    qty=0;
  else 
    qty=parseInt(value)
  let modData=data.data;
  let values=modData.get(colID)  
  values.pe_qty=qty;
  console.log("modfied data =",modData,values,qty,greeksData);
  // let modGreeksData=JSON.stringify(JSON.parse(greeksData));
  let modGreeksData=greeksStateRef.current.greeksData;
  console.log("modGreeksData=",modGreeksData,greeksData,greeksStateRef.current.greeksData)
  let index=modGreeksData.findIndex(item=>item.key==data.key)
  console.log("index and greeksdata at index=",index,modGreeksData[index]);
  modGreeksData[index].data.set(colID,values);
  console.log("Final data=",modGreeksData);
  setGreeksState((prevState) => ({
    ...prevState,
    greeksData:modGreeksData,
    greeksResponse:greeksStateRef.current.greeksResponse,
    isCompleteRefresh:false,
   }));
   setIsDirty(true);
}

const openLineChart=(type,data)=>{
  if(OPTIONS_TABLE_CHARTS.CHART_TYPE_CALL==type){
    const symbol=(data.ce_instr)
    if(isEliteUser)
      window.open( `${process.env.REACT_APP_URL}candle-line-chart?type=SINGLE&symbol=${symbol}&startDate=&nDays=0`, '_blank', 'noreferrer');
    else
      window.open( `${process.env.REACT_APP_URL}line-chart?type=SINGLE&symbol=${symbol}&startDate=&nDays=0`, '_blank', 'noreferrer');
   
  }else if(OPTIONS_TABLE_CHARTS.CHART_TYPE_PUT==type){
    const symbol=(data.pe_instr)
    if(isEliteUser)
      window.open( `${process.env.REACT_APP_URL}lcandle-ine-chart?type=SINGLE&symbol=${symbol}&startDate=&nDays=0`, '_blank', 'noreferrer');
    else
    window.open( `${process.env.REACT_APP_URL}line-chart?type=SINGLE&symbol=${symbol}&startDate=&nDays=0`, '_blank', 'noreferrer');
  
  }
  else if(OPTIONS_TABLE_CHARTS.CHART_TYPE_CALL_PUT_BOTH==type){
    const symbol=data.pe_instr+"_"+(data.ce_instr.slice(2))
    if(isEliteUser)
      window.open( `${process.env.REACT_APP_URL}candle-line-chart?type=SINGLE&symbol=${symbol}&startDate=&nDays=0`, '_blank', 'noreferrer');
    else
      window.open( `${process.env.REACT_APP_URL}line-chart?type=SINGLE&symbol=${symbol}&startDate=&nDays=0`, '_blank', 'noreferrer');
 
  }
}

const openMPChart=(type,data)=>{
  if(OPTIONS_TABLE_CHARTS.CHART_TYPE_CALL==type){
    const symbol=(data.ce_instr)
    window.open(`${process.env.REACT_APP_URL}market-profile?symbol=${symbol}`, '_blank', 'noreferrer');
   
  }else if(OPTIONS_TABLE_CHARTS.CHART_TYPE_PUT==type){
    const symbol=(data.pe_instr)
    window.open(`${process.env.REACT_APP_URL}market-profile?symbol=${symbol}`, '_blank', 'noreferrer');
   
  }
  else if(OPTIONS_TABLE_CHARTS.CHART_TYPE_CALL_PUT_BOTH==type){
    const symbol=data.pe_instr+"_"+(data.ce_instr.slice(2))
    window.open(`${process.env.REACT_APP_URL}market-profile?symbol=${symbol}`, '_blank', 'noreferrer');
   
  }
}


const [columnDefs,setColumnDefs] = useState()
  
useEffect(()=>{
    console.log("useEffect greeksState state=",greeksState.greeksData,greeksState.greeksResponse)
    // optionsStateRef.current=optionsState;
    greeksStateRef.current = greeksState;
    greeksDataRef.current=greeksState.greeksData;
    greeksResponseRef.current=greeksState.greeksResponse;
    if(gridRef!=undefined && gridRef.current!=undefined && gridRef.current.api!=undefined){
      let params = {
          force: true,
          suppressFlash: false,
          // rowNodes: changedRow,
        }; 
        console.log("refresh grid")
        // const columnDef=getColumneDef(handleCallCheckBoxChange,handlePutCheckBoxChange,auth,openLineChart,openMPChart,greeksResponse.expiry_list,greeksResponse.data,false)
        // setColumnDefs(columnDef)
        gridRef.current.api.refreshCells(params);
        if(greeksStateRef.current.isCompleteRefresh ){
        // gridRef.current.api.setRowData( greeksData );
         
        }
        // let isDirty=checkNonZeroPositionPresent();
        // setIsDirty(isDirty)
        calculateGainLoss();
      }
  },[greeksState])

  useEffect(()=>{
      if(selectedInstrument!=undefined && tickerHeight!=undefined){
        setSelectedRows({call:undefined,put:undefined})
        setGreeksState((prevState) => ({
          ...prevState,
          isPollingAllowed:false,
          instrument:selectedInstrument}));
        
        let data={"symbol": selectedInstrument,"c": userSettings.data.data_in_contracts}
        executeAPIGreeks(URL.GREEK_TABLE,"POST",data);
      }
    },[selectedInstrument,tickerHeight]);

    
    const handleTabChange = (event, newValue) => {
      setTabState(prev=>({
        ...prev,
        selectedInstrument:newValue
      }));
     
      resetTarget(false);
      setPositionState((prevState)=>({
        ...prevState,
        selectedPositionIndex:0
      }))

    
    };

    const setTimeSlider=(data)=>{
      if(data==undefined)
      data=greeksResponse;
      // if(data!=undefined && (targetDate==undefined||targetDate=="")) {
        if(data!=undefined) {
       
        let dateTimeSplit=data.lastDataTime.split(" ");
        let index=GREEKS_TABLE_TARGET_TIME.findIndex((item)=>(dateTimeSplit[1].toString())<item.time);
        console.log("greeks data in settimeslider=",data,index);
        console.log("time index val",index,GREEKS_TABLE_TARGET_TIME[index],dateTimeSplit[1]);
        if(index==-1){
          setTimeMinValue(0);
          setTargetState((prevState)=>({
            ...prevState,
            targetTime:GREEKS_TABLE_TARGET_TIME[0]
          }))
        }
        else{
          if(index+1<GREEKS_TABLE_TARGET_TIME.length){
            setTimeMinValue(GREEKS_TABLE_TARGET_TIME[index].value)
            setTargetState((prevState)=>({
              ...prevState,
              targetTime:GREEKS_TABLE_TARGET_TIME[index]
            }))
          }
            
          else{
          setTimeMinValue(GREEKS_TABLE_TARGET_TIME[GREEKS_TABLE_TARGET_TIME.length-1].value) 
          setTargetState((prevState)=>({
            ...prevState,
            targetTime:GREEKS_TABLE_TARGET_TIME[GREEKS_TABLE_TARGET_TIME.length-1]
          }))
          }
        
        }
       
      }
      
    }

    const resetTimeSlider=()=>{
      setTimeMinValue(0);
          setTargetState((prevState)=>({
            ...prevState,
            targetTime:GREEKS_TABLE_TARGET_TIME[0]
          }))
    }
/**
 * API response handler greeks data
 */  
   useEffect(() => {
    if(loadedGreeks){
      console.log("loadedOptions=true",responseDataGreeks);
      if(responseDataGreeks!=null){
        setApiResponse(responseDataGreeks);
        console.log("--locale", userSettings.data.number_format_locale)
        let processedData=processGreekTableData(responseDataGreeks.data,responseDataGreeks.highlight_strike,userSettings.data.number_format_locale);
        // processedData=modifyProcessedData(processedData,greeksData);
        const columnDef=getColumneDef(handleCallCheckBoxChange,handlePutCheckBoxChange,auth,openLineChart,openMPChart,responseDataGreeks.expiry_list,responseDataGreeks.data,false,isBasicUser,responseDataGreeks.lastDataTime)
        
        setColumnDefs(columnDef)
        // let dateTimeSplit=responseDataGreeks.lastDataTime.split(" ");
        // let index=GREEKS_TABLE_TARGET_TIME.findIndex((item)=>(dateTimeSplit[1].toString())<item.time);
        // console.log("time index val",index,GREEKS_TABLE_TARGET_TIME[index],dateTimeSplit[1]);
        console.log("greeks settimeslider 1st load=",responseDataGreeks,targetDate)
        setTimeSlider(responseDataGreeks)
        let tableHeight=window.innerHeight-tickerHeight-TAB_CONTAINER_HEIGHT-15-48-18-32-32-32-110;//margin top, top header, scroll heights, bottom bar,copyright footer
        tableHeight=Math.max(tableHeight,MIN_TABLE_HEIGHT);
        console.log("innerheight real=",window.innerHeight,tableHeight,apiResponse);
        // greeksResponseRef.current=responseDataGreeks;
        // greeksDataRef.current=processedData;
        let updateInterval=pollingInterval;
              if(responseDataGreeks.isInstrumentLive!=undefined && responseDataGreeks.isInstrumentLive){
                updateInterval=reloadInerval;
              }
              setGreeksState((prevState) => ({...prevState,
                refreshInterval:updateInterval,
                isPollingAllowed:true,
                tableHeight:tableHeight,
                actualHeight:actualHeight,
                greeksLoaded:true,
                greeksData:processedData,
                greeksResponse: responseDataGreeks,
                isCompleteRefresh:true,
                isTradingSessionActive:responseDataGreeks.isTradingSessionActive,
                isStatusCheckAllowed:(!responseDataGreeks.isInstrumentLive),
                statusCheckPollingInterval:(responseDataGreeks.isTradingSessionActive?CHART_RELOAD_TIME.FAST_POLLING:CHART_RELOAD_TIME.POLLING_TIME),
                isInstrumentLive:responseDataGreeks.isInstrumentLive}));
                  
      }
      else if(errorGreeks!==null){
        setGreeksState((prevState) => ({
          ...prevState,
          greeksResponse:greeksResponse,
          greeksLoaded:true}));

        console.log("Error data=",errorGreeks);

          setMsgState({open:true,msg:errorGreeks?.response?.data?.message ?? `${TEXT_MSGS.NETWORK_ERROR_MSG}`,severity:"info"});
          //if unauthorized then redirec it to login page
          if(errorGreeks?.response?.status === 401 || errorGreeks?.response?.status === 403){
            console.log("status received =",errorGreeks?.response?.status)
            navigate(from, { replace: true });
          }
      }
      resetGreeks();
    }
    },[loadedGreeks,responseDataGreeks]);


  
    
  //live data polling
 //polling or live chart update handler
  useEffect(() => {
   //set the ref to current state
   
   greeksStateRef.current = greeksState;
    
    const timeout = setInterval(() => {
      if(greeksStateRef.current.instrument!=undefined){
        // console.log("Live use effect api called=",greeksStateRef.current.refreshInterval);
        if(greeksStateRef.current.isPollingAllowed){
         console.log("Live use effect api called=",greeksStateRef.current.refreshInterval);
          let data={"symbol": greeksStateRef.current.instrument,"c": userSettings.data.data_in_contracts}
          executeLive(URL.GREEK_TABLE,"POST",data);
          //   executeAPIGreeks(URL.GREEK_TABLE,"POST",data);
        }
     }
  }, greeksStateRef.current.refreshInterval);
   
    //market status polling
    const timeoutStatusCheck=setInterval(() => {
      if(greeksStateRef.current.isStatusCheckAllowed && !greeksStateRef.current.isInstrumentLive)   {
          executeAPIStatus(URL.CHART_STATUS,"POST",{symbol:greeksStateRef.current.instrument});
      }
    },greeksStateRef.current.statusCheckPollingInterval);        
        
    return () => {
      // just to clear the timeout when component unmounts
        clearInterval(timeout);
        clearInterval(timeoutStatusCheck)
    };
}, [greeksState]);
    
/**
 * Market live status check response handler
 */  
useEffect(() => {
  if(loadedStatus){
    if(responseDataStatus!=null){
              //if instrument is live then load the live data else set the status as per the response
              // console.log("Greeks status =",responseDataStatus,responseDataStatus.upgrade_notify);
              setShowPopup(false);
              if(responseDataStatus.isInstrumentLive!=undefined && responseDataStatus.isInstrumentLive){
                let data={"symbol": greeksStateRef.current.instrument,"c": userSettings.data.data_in_contracts}
                executeLive(URL.GREEK_TABLE,"POST",data);
              }else{
                setGreeksState((prevState) => ({
                  ...prevState,
                  isStatusCheckAllowed:(!responseDataStatus.isInstrumentLive),
                  statusCheckPollingInterval:(responseDataStatus.isTradingSessionActive?CHART_RELOAD_TIME.FAST_POLLING:CHART_RELOAD_TIME.POLLING_TIME),
                  isTradingSessionActive:responseDataStatus.isTradingSessionActive,
                  isInstrumentLive:responseDataStatus.isInstrumentLive,
                }));
              }
              if(responseDataStatus.upgrade_notify!=undefined && responseDataStatus.upgrade_notify){
                // console.log("Greeks status inside=",responseDataStatus);
                setShowPopup(true);
              }
          
    }
    else if(errorStatus!==null){
      console.log("Error data status check=",errorStatus);

      // setGreeksState((prevState) => ({
      //   ...prevState,
      //   optionsLoaded:true}));
        
        if(errorStatus?.response?.status === 401 || errorStatus?.response?.status === 403){
          console.log("status received =",errorStatus?.response?.status)
          // navigate(from, { replace: true });
          setLoginMsg(errorStatus?.response?.data?.message);
          setShowLoginPopup(true);
        }
    }
    resetStatus();
  }
  },[loadedStatus,responseDataStatus]);

  
 /**
 * API Live response handler options data
 */  
  useEffect(() => {
    if(loadedLive){
      console.log("loadedOptions Live=true",responseDataLive,greeksResponse);
      if(responseDataLive!=null){
        
        console.log("Live processed data before =",greeksResponse,responseDataLive);
        setApiResponse(responseDataLive)
        let processedData=processGreekTableData(responseDataLive.data,responseDataLive.highlight_strike,userSettings.data.number_format_locale);
       
       
        processedData=modifyProcessedData(processedData,greeksData);
        console.log("Live processed data after=",processedData,greeksResponse,responseDataLive);
        // if(targetIV==undefined && (targetDate==undefined|| targetDate=="") && targetIV==undefined)
        if(targetDate==undefined|| targetDate=="")
          setTimeSlider(responseDataLive)
        else{
          console.log("Greeks target not set");
        // resetTimeSlider()
        }
       
        let tableHeight=window.innerHeight-tickerHeight-TAB_CONTAINER_HEIGHT-15-48-18-32-32-32-110;//margin top, top header, scroll heights, bottom bar,copyright footer
        tableHeight=Math.max(tableHeight,MIN_TABLE_HEIGHT);
       
        
        let updateInterval=pollingInterval;
              if(responseDataLive.isInstrumentLive!=undefined && responseDataLive.isInstrumentLive){
                updateInterval=reloadInerval;
              }
              setGreeksState((prevState) => ({
                ...prevState,
                refreshInterval:updateInterval,
                tableHeight:tableHeight,
                greeksLoaded:true,
                greeksData:processedData,
                greeksResponse: responseDataLive,
                isCompleteRefresh:false,
                isTradingSessionActive:responseDataLive.isTradingSessionActive,
                isStatusCheckAllowed:(!responseDataLive.isInstrumentLive),
                statusCheckPollingInterval:(responseDataLive.isTradingSessionActive?CHART_RELOAD_TIME.FAST_POLLING:CHART_RELOAD_TIME.POLLING_TIME),
                isInstrumentLive:responseDataLive.isInstrumentLive}));
                  
      }
      else if(errorLive!==null){
        setGreeksState((prevState) => ({
          ...prevState,
          greeksResponse: greeksResponse,
          greeksLoaded:true}));

        console.log("Error data=",errorLive);

          // setMsgState({open:true,msg:errorLive?.response?.data?.message ?? `${TEXT_MSGS.NETWORK_ERROR_MSG}`,severity:"info"});
          //if unauthorized then redirec it to login page
          if(errorLive?.response?.status === 401 || errorLive?.response?.status === 403){
            console.log("status received =",errorLive?.response?.status)
            // navigate(from, { replace: true });
            setLoginMsg(errorLive?.response?.data?.message);
            setShowLoginPopup(true);
          }
      }
      resetLive();
    }
    },[loadedLive,responseDataLive]);

    //user positions on load
    useEffect(()=>{
      if(!isBasicUser)
        executeAPIPosition(URL.USER_POSITIONS,"GET",{});
      else{
        let data=[];
          data.push(NEW_POSITION)
          setPositionState((prevState) => ({...prevState,
            positionLoaded:true,
            positionData:data,
            selectedPositionIndex:0,
            }));

      }
    },[])
    
    //calcualte gain or loss when position changes
    useEffect(()=>{
      calculateGainLoss()
    },[selectedPositionIndex,positionData])

    //api handler for user get positions
    useEffect(() => {
      if(loadedPosition){
        console.log("loaded user positions target=====>",responseDataPosition);
        if(responseDataPosition!=null){
                let data=[];
                data.push(NEW_POSITION)
                console.log("position data=",data)
                let processedData=processGreekPositionData(responseDataPosition.data);
                if(processedData && processedData.length>0){
                  data=[...data,...processedData];
                  console.log("final position data, positionName =",data,positionName);
                }
                if(positionName!=undefined && positionName!=""){
                  let index=data.findIndex((item)=>item.value==positionName)
                  console.log("new index=",index)
                  if(index==-1) index=0;
                  setPositionState((prevState) => ({...prevState,
                    positionLoaded:true,
                    positionData:data,
                    selectedPositionIndex:index,
                    // positionData:responseDataPosition,
                    }));
                    setIsDirty(false);
                }else if(selectedPositionIndex!=0 && existingPositionName!=undefined && existingPositionName!=""){
                  let index=data.findIndex((item)=>item.value==existingPositionName)
                  console.log("existing index=",index)
                  if(index==-1) index=0;
                  setPositionState((prevState) => ({...prevState,
                    positionLoaded:true,
                    positionData:data,
                    selectedPositionIndex:index,
                    // positionData:responseDataPosition,
                    }));
                    setIsDirty(false);
                }
                else {
                  setPositionState((prevState) => ({...prevState,
                  positionLoaded:true,
                  positionData:data,
                  selectedPositionIndex:0,
                  // positionData:responseDataPosition,
                  }));
                }
                    
        }
        else if(errorPosition!==null){
          let data=[];
          data.push(NEW_POSITION)
          setPositionState((prevState) => ({...prevState,
            positionLoaded:true,
            positionData:data,
            selectedPositionIndex:0,
            }));
  
          console.log("Error data=",errorPosition);
  
            
            //if unauthorized then redirec it to login page
            if(errorPosition?.response?.status === 401 || errorPosition?.response?.status === 403){
              console.log("status received =",errorPosition?.response?.status)
              navigate(from, { replace: true });
            }else
            setMsgState({open:true,msg:errorPosition?.response?.data?.message ?? `${TEXT_MSGS.NETWORK_ERROR_MSG}`,severity:"info"});
        }
        resetPosition();
      }
      },[loadedPosition,responseDataPosition]);
  


  /**
 * API response handler greeks data with target consideration
 */  
   useEffect(() => {
    if(loadedGreeksTarget){
      console.log("loadedGreeks target=====>",responseDataGreeksTarget);
      if(responseDataGreeksTarget!=null){
        setTargetState((prevState)=>({
          ...prevState,
          isTargetCalculated:true
        }))
        
        let processedData=processGreekTableData(responseDataGreeksTarget.data,responseDataGreeksTarget.highlight_strike,userSettings.data.number_format_locale);
        processedData=modifyProcessedData(processedData,greeksData);
        
        const columnDef=getColumneDef(handleCallCheckBoxChange,handlePutCheckBoxChange,auth,openLineChart,openMPChart,responseDataGreeksTarget.expiry_list,responseDataGreeksTarget.data,true,isBasicUser,responseDataGreeksTarget.lastDataTime)
        setColumnDefs(columnDef)
        
        let tableHeight=window.innerHeight-tickerHeight-TAB_CONTAINER_HEIGHT-15-48-18-32-32-32-100;//margin top, top header, scroll heights, bottom bar,copyright footer
        tableHeight=Math.max(tableHeight,MIN_TABLE_HEIGHT);
        console.log("innerheight real=",window.innerHeight,tableHeight);
        let updateInterval=pollingInterval;
              if(responseDataGreeksTarget.isInstrumentLive!=undefined && responseDataGreeksTarget.isInstrumentLive){
                updateInterval=reloadInerval;
              }
              setGreeksState((prevState) => ({...prevState,
                refreshInterval:updateInterval,
                isPollingAllowed:false,
                tableHeight:tableHeight,
                actualHeight:actualHeight,
                greeksLoaded:true,
                greeksData:processedData,
                greeksResponse:responseDataGreeksTarget,
                isCompleteRefresh:false,
                isInstrumentLive:responseDataGreeksTarget.isInstrumentLive}));

                  
      }
      else if(errorGreeksTarget!==null){
        setGreeksState((prevState) => ({
          ...prevState,
          greeksResponse:greeksResponse,
          isPollingAllowed:!isTargetCalculated,
          greeksLoaded:true}));

        console.log("Error data=",errorGreeksTarget);

          
          //if unauthorized then redirec it to login page
          if(errorGreeksTarget?.response?.status === 401 || errorGreeksTarget?.response?.status === 403){
            console.log("status received =",errorGreeksTarget?.response?.status)
            // navigate(from, { replace: true });
            setLoginMsg(errorGreeksTarget?.response?.data?.message);
            setShowLoginPopup(true);
          }else
          setMsgState({open:true,msg:errorGreeksTarget?.response?.data?.message ?? `${TEXT_MSGS.NETWORK_ERROR_MSG}`,severity:"info"});
      }
      resetGreeksTarget();
    }
    },[loadedGreeksTarget,responseDataGreeksTarget]);

    
    const onGridReady = params => {
      setGridAPIOptions(params.api);
      setColumnAPIOptions(params.columnApi);
    //   gridRef.current.api.setRowData( greeksData );
    };

    
   
   /**
 * autoclosing of the snackbar msg bar 
 */ 
    const handleClose = (event, reason) => {
      setMsgState({...msgState,open:false});
  };
  
  const setTickerHeight=(height)=>{
    console.log("ticker height=",height)
    setTickerTableHeight(height);
  }

  
  const saveHandler=()=>{
    const nonZeroFieldPresent=checkNonZeroPositionPresent()
    if(nonZeroFieldPresent){
        if(selectedPositionIndex==0){     //new position
          setSavePositionState({
            openSaveDialog:true,
            positionName:"",
            targetFlow:targetFlow
          })
      }else{
        //ask the user to override the existing
        setSaveExistingPositionState({
          existingOpenSaveDialog:true,
          existingPositionName:positionData[selectedPositionIndex].value,
          existingTargetFlow:existingTargetFlow
        })
      }
    }else{
      console.log("nothing to save")
      setMsgState({open:true,msg:errorGreeks?.response?.data?.message ?? `${TEXT_MSGS.POSITION_NOTHING_TO_SAVE}`,severity:"info"});
    }  
  }

  const deleteHandler=()=>{
   setdDletePositionState({
    openDeleteDialog:true,
    deletePositionName:positionData[selectedPositionIndex].value
   })
  }

  const resetHandler=()=>{
   clearGreeks();
   }

  const clearGreeks=(index)=>{
    if(index==undefined)index=selectedPositionIndex
    let modGreeksData=greeksStateRef.current.greeksData;
    if(modGreeksData!=undefined){
      const nonZeroFieldPresent=checkNonZeroPositionPresent();
      if(nonZeroFieldPresent){
        if(index==0){
        const processedData=processGreekTableData(greeksResponse.data,greeksResponse.highlight_strike,userSettings.data.number_format_locale);
        setGreeksState((prevState) => ({
          ...prevState,
          greeksData:processedData,
          greeksResponse:greeksResponse,
          isCompleteRefresh:true
          }));
        }else{
          modifyGreeksDataForSelectedPosition(index);
        }
      }
    }
    setIsDirty(false);
  }
  const cancelSaveHandler=()=>{
    if(targetFlow)
    CalculateTarget();
    setSavePositionState({
      openSaveDialog:false,
      positionName:"",
      targetFlow:false
    })
  }

 
  const savePosition=(name)=>{
    console.log("position name=",name);
    setSavePositionState({
      openSaveDialog:false,
      positionName:name,
      targetFlow:targetFlow
    })
   
   console.log("save existing position= 0th row data=", greeksData[0].data)
    let innerMap=new Map();
    let isFound=false;
    for(let i=0;i<greeksData.length;i++){
      // console.log("pos1=", greeksData[i].data);
      let tempMap=new Map();
      for (let [key, value] of  greeksData[i].data.entries()) {
        // console.log(key, value,greeksData[i].key)
          if(value.ce_qty !=0 ||value.pe_qty !=0){
            isFound=true;
        //   console.log("Found non zero=",value,key,value.date,greeksData[i].key);
          let tempData={};
          tempData.ce_ltp=value.ce_ltp;
          tempData.ce_qty=value.ce_qty;
          tempData.ce_iv=value.ce_iv;
          tempData.pe_ltp=value.pe_ltp;
          tempData.pe_qty=value.pe_qty;
          tempData.pe_iv=value.pe_iv;
          tempMap.set(value.date,tempData);
        }
        
       
      }
      // console.log()
      if(tempMap.size>0){
        console.log("tempmap=",tempMap)

        innerMap.set(greeksData[i].key,Object.fromEntries(tempMap))
      }
      
    }
    console.log("Save pos=  greeksData=",greeksData,greeksData.length,greeksData[0].data.keys(),innerMap,JSON.stringify(Object.fromEntries(innerMap)));
    
    if(isFound){
      let reqData={
        "spot_ltp": greeksResponse.spot_ltp,
        "fut_ltp": greeksResponse.fut_ltp,
        "time": greeksResponse.lastDataTime,
      }
      //api call
      // reqData[name]=(Object.fromEntries(innerMap));
      reqData["position_data"]=(Object.fromEntries(innerMap));
      let posData=new Map();
      posData.set(name,reqData)
      // posData.set(name+"copy",reqData)
      let apiData=Object.fromEntries(posData);
      // posData.set("position_data",Object.fromEntries(innerMap))
      console.log("Req data=",reqData,posData,Object.fromEntries(posData),apiData);
      executeSaveAPIPosition(URL.UPDATE_USER_POSITION,"POST",apiData);

      
    }else{
      //nothing to save
    }
  }

  const checkNonZeroPositionPresent=()=>{
    if(greeksData!=undefined){
      for(let i=0;i<greeksData.length;i++){
        for (let [key, value] of  greeksData[i].data.entries()) {
          if(value.ce_qty !=0 ||value.pe_qty !=0){
            console.log("non zero present value=",value)
            return true;
          }
        }
      }
      return false;
    }else{
      return false;
    }
  }

  const modifyProcessedData=(newData,prevData)=>{
    if(prevData!=undefined){
      for(let i=0;i<prevData.length;i++){
        for (let [key, value] of  prevData[i].data.entries()) {
          if(value.ce_qty !=0 ||value.pe_qty !=0){
            
            for(let j=0;j<newData.length;j++){
              for (let [key1, value1] of  newData[j].data.entries()) {
                if(key==key1 && prevData[i].key==newData[j].key){
                  // console.log("found non zero in old data i,j,key, key1=",i,j,key,key1,value,value1,prevData[i].key);
                  value1.ce_qty=value.ce_qty;
                  value1.pe_qty=value.pe_qty;
                  value1.ce_cost=value.ce_cost;
                  value1.pe_cost=value.pe_cost;
                  newData[j].data.set(key1,value1);
                //   console.log("found non zero in old data i,j,key, key1=",value1.ce_qty);
                  break;

                }
              
              }
            }
            // break;
          }
        }
      }
    }
    return newData;
  }

   //api handler for user save/update position
   useEffect(() => {
    if(loadedSavePosition){
      console.log("loadedsave position target=====>",responseDataSavePosition);
      if(responseDataSavePosition!=null){
           console.log("position saved succesfully")
           setMsgState({open:true,msg:errorGreeks?.response?.data?.message ?? `${TEXT_MSGS.POSITION_SAVED_SUCCESS}`,severity:"success"});   
           if(positionName!=undefined && positionName!=""){
            executeAPIPosition(URL.USER_POSITIONS,"GET",{});
           }
           if(selectedPositionIndex!=0 && existingPositionName!=undefined && existingPositionName!=""){
            executeAPIPosition(URL.USER_POSITIONS,"GET",{});
           }
           if(targetFlow){
            CalculateTarget();
           }
       
                  
      }
      else if(errorSavePosition!==null){
       

        console.log("Error data=",errorSavePosition);

          
          //if unauthorized then redirec it to login page
          if(errorSavePosition?.response?.status === 401 || errorSavePosition?.response?.status === 403){
            console.log("status received =",errorSavePosition?.response?.status)
            // navigate(from, { replace: true });
            setLoginMsg(errorSavePosition?.response?.data?.message);
            setShowLoginPopup(true);
          }else
          setMsgState({open:true,msg:errorSavePosition?.response?.data?.message ?? `${TEXT_MSGS.NETWORK_ERROR_MSG}`,severity:"info"});
      }
      resetSavePosition();
    }
    },[loadedSavePosition,responseDataSavePosition]);




  const resetTarget=(isAPICallRequired=true)=>{

    setTargetState({
      targetIndex:"",
      // targetDate:dayjs(),
      targetDate:"",
      targetIV:"",
      targetTime:GREEKS_TABLE_TARGET_TIME[0],
      isTargetCalculated:false,
      targetValue:undefined
    })
    setGreeksState((prevState)=>({
      ...prevState,
      greeksResponse:greeksResponse,
      isPollingAllowed:true,
    }))
    setPositionState((prevState)=>({
      ...prevState,
      selectedPositionIndex:0
    }))

   
    //todo: check if it required as we are calling greeks chart api
    if(isAPICallRequired){
      let data={"symbol": selectedInstrument,"c": userSettings.data.data_in_contracts}
      executeAPIGreeks(URL.GREEK_TABLE,"POST",data);
    }
    calculateGainLoss();
    
    
  }

  const PopulateLTP=()=>{
    if(greeksResponse!=undefined && greeksResponse!=null){
    let date=greeksResponse.lastDataTime.split(" ");
    let index=0
    let isSessionActive=date[1].toString()<MARKET_CLOSE_TIME ?true:false;
   
    let newDate=!isSessionActive && (date[1].toString()>"15:29")?(dayjs().add(1,'day')):dayjs();
    console.log("isSessionActive=",isSessionActive,dayjs().add(1,'day'),newDate, date[1].toString(), date[1].toString()>MARKET_OPEN_TIME)

    setTargetState((prevState)=>({
      ...prevState,
      targetIndex:greeksResponse.spot_ltp,
      targetDate:newDate,
      targetIV:0,
      targetTime:GREEKS_TABLE_TARGET_TIME[0],
    }))
  }
  setTimeSlider()

  }
  

  const handleTargetIndexChange=(event)=>{
    setTargetState((prevState)=>({
      ...prevState,
      targetIndex:event.target.value
    }))
  }

  const onChange = (date, dateString) => {
    const currDate=new Date();
    let today=(dateString == dayjs().startOf('day'))
    // const isToday=dayjs(dateString).isSame(dayjs(date).format(DateFormat));
    const isToday=dayjs().format(DateFormat)==dateString
    console.log("onChange",date, dateString,isToday,today,dayjs().format(DateFormat));
    
    //if today date is selected then set the time slider accordingly
    if(isToday){
      setTimeSlider()
    }else{
      resetTimeSlider()
    }
    setTargetState((prevState)=>({
      ...prevState,
      targetDate:dayjs(date)
    }))
    

  };


  const handleTargetDateChange=(event)=>{
    setTargetState((prevState)=>({
      ...prevState,
      targetDate:event.target.value
    }))
  }
  const handleTargetIVChange=(event)=>{
    // let newValue=event.target.value.replace(".","");
    setTargetState((prevState)=>({
      ...prevState,
      targetIV:event.target.value
    }))
  }
  const handleTargetTimeChange=(event)=>{
    let index=GREEKS_TABLE_TARGET_TIME.findIndex((item)=>item.value==event.target.value)
    if(index!=-1){
      console.log("changed time=",GREEKS_TABLE_TARGET_TIME[index]);
      if(GREEKS_TABLE_TARGET_TIME[index].value>=timeMinValue)
    setTargetState((prevState)=>({
      ...prevState,
      targetTime:GREEKS_TABLE_TARGET_TIME[index]
    }))
    else
    return
  }
  }

  const disabledDate=(current)=> {
    
    if(greeksResponse!=undefined){
      let dateTimeSplit=greeksResponse.lastDataTime.split(" ");
        let index=GREEKS_TABLE_TARGET_TIME.findIndex((item)=>(dateTimeSplit[1].toString())<item.time);
        // console.log(dateTimeSplit[1].toString(), dayjs().format(TimeFormat), MARKET_CLOSE_TIME, dateTimeSplit[1].toString()>=MARKET_CLOSE_TIME, dateTimeSplit[1].toString() > MARKET_OPEN_TIME)
        if(dateTimeSplit[1].toString()>=MARKET_CLOSE_TIME && dayjs().format(TimeFormat) > MARKET_OPEN_TIME)
          return (current && current < dayjs().endOf('day')) ||dayjs(current).day()==6 || dayjs(current).day()==0  //next day and weekends
        else
          return (current && current <= dayjs().startOf('day')) ||dayjs(current).day()==6 || dayjs(current).day()==0//same day and weekends
    }
    else
     return (current && current <= dayjs().startOf('day'))  ||dayjs(current).day()==6 || dayjs(current).day()==0

    
  }

  const CalculateTarget=()=>{
    setGreeksState((prevState) => ({
      ...prevState,
      isPollingAllowed:false}));

    console.log("Greeks =",targetIndex,targetDate,targetIV)
   
    let data={
        "symbol": selectedInstrument,
        "tSpot": targetIndex,
        "tDate": dayjs(targetDate).format(DateFormat),
        "tTime": targetTime.time,
        "ivc": targetIV,
        "c": userSettings.data.data_in_contracts
    }
    executeAPIGreeksTarget(URL.GREEK_TABLE,"POST",data);

  }

  const CallTargetCalculation=()=>{
    if(selectedPositionIndex==0){
      const nonZeroFieldPresent=checkNonZeroPositionPresent();
      if(nonZeroFieldPresent){
        setSavePositionState({
          openSaveDialog:true,
          positionName:"",
          targetFlow:true
        })
      }
      else
        CalculateTarget();
    }else{
      {
        const nonZeroFieldPresent=checkNonZeroPositionPresent();
        if(nonZeroFieldPresent && isDirty){
          setSaveExistingPositionState({
           existingOpenSaveDialog:true,
           existingPositionName:positionData[selectedPositionIndex].value,
           existingTargetFlow:true
          })
        }else{
          CalculateTarget();
        }
      }
    }
  }

  const handlePositionChange=(event)=>{
    const newValue=event.target.value;
    const newIndex=positionData.findIndex((item)=>item.value==newValue)
    const nonZeroPosPresent=checkNonZeroPositionPresent();
    if(newIndex!=selectedPositionIndex){
     
      console.log("nonzero pos while pos change value==============>",nonZeroPosPresent)
      setPositionState((prevState)=>({
        ...prevState,
        selectedPositionIndex:newIndex
      }));
      modifyGreeksDataForSelectedPosition(newIndex)
      //change the selected position index

    }
    setIsDirty(false);
  }
  
  const modifyGreeksDataForSelectedPosition=(index)=>{
    let modGreeksData=processGreekTableData(greeksResponse.data,greeksResponse.highlight_strike,userSettings.data.number_format_locale);
    if(index!=0){
      const posData=positionData[index].data.position_data;
      for(const key in posData){
        // console.log("pos data key and value=",key,posData[key])
        let greekIndex=modGreeksData.findIndex((item)=>item.key==key)
        if(greekIndex!=-1){
          console.log("index found=",greekIndex,modGreeksData[greekIndex]);
          for(const keyInner in posData[key]){
            
            // console.log("keyInner and data=",keyInner,posData[key][keyInner])
            let callData=modGreeksData[greekIndex].data.get("call"+keyInner)
            let putData=modGreeksData[greekIndex].data.get("put"+keyInner)
            // console.log("call and put data",callData,putData)
            if(callData!=undefined){
              callData.ce_qty=posData[key][keyInner].ce_qty;
              callData.pe_qty=posData[key][keyInner].pe_qty;
              callData.ce_cost=posData[key][keyInner].ce_ltp;
              callData.pe_cost=posData[key][keyInner].pe_ltp;
              
              modGreeksData[greekIndex].data.set("call"+keyInner,callData);
              console.log("mod calldata and greeks=",posData[key][keyInner].ce_qty,posData[key][keyInner].pe_qty,callData,modGreeksData[index].data.get("call"+keyInner),modGreeksData);
            }
            if(putData!=undefined){
              putData.ce_qty=posData[key][keyInner].ce_qty;
              putData.pe_qty=posData[key][keyInner].pe_qty;
              putData.ce_cost=posData[key][keyInner].ce_ltp;
              putData.pe_cost=posData[key][keyInner].pe_ltp;
              // console.log("mod putdata=",putData);
             
              modGreeksData[greekIndex].data.set("put"+keyInner,putData);
            }
            


          }

        }
      }
      console.log("mpd greeks data=",modGreeksData)
    //   let processedData=processGreekTableData(greeksResponse.data,greeksResponse.highlight_strike);
    //   processedData=modGreeksData
      setGreeksState((prevState) => ({
        ...prevState,
        greeksData:modGreeksData,
        greeksResponse:greeksResponse,
        isCompleteRefresh:true
       }));
    //    gridRef.current.api.setRowData( processedData );
      
  
    }else{
        let processedData=processGreekTableData(greeksResponse.data,greeksResponse.highlight_strike,userSettings.data.number_format_locale);
      // processedData=processGreekTableData(apiResponse.data,apiResponse.highlight_strike);
        setGreeksState((prevState) => ({
          ...prevState,
          greeksData:processedData,
          greeksResponse:greeksResponse,
          isCompleteRefresh:true
          }));
        //   gridRef.current.api.setRowData( processedData ); 
    }

  }

  const cancelExistingSaveHandler=()=>{
    if(existingTargetFlow)
    CalculateTarget()
    setSaveExistingPositionState({
      existingOpenSaveDialog:false,
      existingPositionName:"",
      existingTargetFlow:false
    })
  }

  const saveExistingPosition=()=>{
    setSaveExistingPositionState({
      existingOpenSaveDialog:false,
      existingPositionName:positionData[selectedPositionIndex].value
    })
    savePosition(positionData[selectedPositionIndex].value)
  }

  const cancelDeleteHandler=()=>{
    setdDletePositionState({
      openDeleteDialog:false,
      deletePositionName:""
    })
  }

  const deletePosition=()=>{
    setdDletePositionState({
      openDeleteDialog:false,
      deletePositionName:positionData[selectedPositionIndex].value
    })
    executeAPIDeletePosition(URL.DELETE_USER_POSITION,"DELETE",{"name_key":positionData[selectedPositionIndex].value})
  }

   //api handler for user delete position
   useEffect(() => {
    if(loadedDeletePosition){
      console.log("loaded delete position =====>",responseDeletePosition);
      if(responseDeletePosition!=null){
        setMsgState({open:true,msg:errorGreeks?.response?.data?.message ?? `${TEXT_MSGS.POSITION_DELETED_SUCCESS}`,severity:"success"});
           console.log("position deleted succesfully")   
           if(deletePositionName!=undefined && deletePositionName!=""){
            // executeAPIPosition(URL.USER_POSITIONS,"GET",{});
            let newPosData=positionData.splice(selectedPositionIndex,1)
            console.log("newposdata=",newPosData);
            setPositionState((prevState) => ({...prevState,
              positionLoaded:true,
              positionData:positionData,
              selectedPositionIndex:0,
              // positionData:responseDataPosition,
              }));
              clearGreeks(0)

           }
           
                  
      }
      else if(errorDeletePosition!==null){
       

        console.log("Error data=",errorDeletePosition);

          
          //if unauthorized then redirec it to login page
          if(errorDeletePosition?.response?.status === 401 || errorDeletePosition?.response?.status === 403){
            console.log("status received =",errorDeletePosition?.response?.status)
            // navigate(from, { replace: true });
            setLoginMsg(errorDeletePosition?.response?.data?.message);
            setShowLoginPopup(true);
          }else
          setMsgState({open:true,msg:errorDeletePosition?.response?.data?.message ?? `${TEXT_MSGS.NETWORK_ERROR_MSG}`,severity:"info"});
      }
      resetDeletePosition();
    }
    },[loadedDeletePosition,responseDeletePosition]);

    const calculateGainLoss=()=>{
      if(positionData!=undefined && greeksData!=undefined){
      let val=0;
      const posData=positionData[selectedPositionIndex].data.position_data;
      if(greeksData!=undefined && posData!=undefined && selectedPositionIndex!=0){
        for(let i=0;i<greeksData.length;i++){
          for (let [key, value] of  greeksData[i].data.entries()) {
            if(value.ce_qty!=undefined && value.ce_qty !=0 && key.includes("call") ){
              // console.log("inside call")
              let temp=posData[greeksData[i].key]
              console.log("inside call temp=",temp,key);
              if(temp!=undefined){
                let data=temp[value.date]
                // console.log("data=",posData,greeksData[i].key,key,value,temp,data,isTargetCalculated)
                
                if(data!=undefined && data.ce_qty!=0 && isTargetCalculated ){
                  // console.log("Call If===>",value.ce_price,data.ce_ltp,data.ce_qty,greeksResponse.lotsize,val,(value.ce_price-data.ce_ltp)*data.ce_qty*greeksResponse.lotsize)
                  val=val+(value.ce_price-data.ce_ltp)*data.ce_qty*greeksResponse.lotsize;

                }else if(data!=undefined && data.ce_qty!=0){
                  console.log("Call Else If===>",value.ce_ltp,data.ce_ltp,data.ce_qty,greeksResponse.lotsize,val)
                  val=val+(value.ce_ltp-data.ce_ltp)*data.ce_qty*greeksResponse.lotsize;
                }
              }

            }
            if(value.pe_qty!=undefined && value.pe_qty !=0  && key.includes("put")){
              // console.log("inside put")
              let temp=posData[greeksData[i].key]
              // console.log("inside call temp=",temp,value)
              if(temp!=undefined){
                let data=temp[value.date]
                // console.log("data=",posData,greeksData[i].key,key,value,temp,data,isTargetCalculated)
                
                if(data!=undefined && data.pe_qty!=0 && isTargetCalculated ){
                  // console.log("Put If===>",value.pe_price,data.pe_ltp,data.pe_qty,greeksResponse.lotsize,val,(value.pe_price-data.pe_ltp)*data.pe_qty*greeksResponse.lotsize)
                  val=val+(value.pe_price-data.pe_ltp)*data.pe_qty*greeksResponse.lotsize;

                }else if(data!=undefined && data.pe_qty!=0){
                  console.log("Put Else If===>",value.pe_ltp,data.pe_ltp,data.pe_qty,greeksResponse.lotsize,val)
                  val=val+(value.pe_ltp-data.pe_ltp)*data.pe_qty*greeksResponse.lotsize;
                }
              }

            }
          }
        } 

      }
      console.log("Loss gain val=",val.toFixed(2));
      setNetProfit(val.toFixed(2));
      setSpotLtp(positionData[selectedPositionIndex].data.spot_ltp);
      setPosTime(positionData[selectedPositionIndex].data.time);
    }
    
  }

  const saveAsNew=()=>{
    setSaveExistingPositionState({
      existingOpenSaveDialog:false,
      existingPositionName:undefined,
      targetFlow:false
    })
    setSavePositionState({
      openSaveDialog:true,
      positionName:"",
      targetFlow:true
    })
  }
  const getRowId = useMemo(() => {
    return (params) => {
      return params.data.key;
    };
  }, []);

  return (
    <div style={{width:"100%",backgroundColor:"#022D5A",zIndex:9,minWidth:`${MIN_TABLE_CONTAINER_WIDTH}`}}>
      {/* <TICKER_TABLE setTickerHeight={setTickerHeight} setHeight={setTickerHeight}/> */}
      <TICKER_TABLE setTickerHeight={setTickerHeight} setHeight={setTickerHeight} showLoginPopup={()=>setShowLoginPopup(true)}/>
      {greeksLoaded?
      <>
       {tabLoaded?
       <div id="tabs" style={{width:"100%",display:"flex",flexDirection:"row"}}>
      <div id="tabs" style={{width:"800px"}}>
      <Tabs
        value={selectedInstrument}
        onChange={handleTabChange}
        size='small'
        sx={{height:24}}
        textColor="primary"
        indicatorsColor="primary"
        aria-label="secondary tabs example"
    >
      {tabData.map((item,index) => (
        <Tab sx={{height:24}} key={item.value} value={item.value} label={item.name} />
      ))}
   </Tabs>
   </div>
   
      </div>:
      <></>
      }

<div id="tabs" style={{width:"100%",display:"flex",flexDirection:"row",marginTop:"10px"}}>
    
    <Typography sx={{fontSize:"18px",ml:3,mt:1}} >
    
        {"Target\n Consideration"}
    </Typography>
        <TextField id="outlined-basic" size="small" label="Target Spot" variant="outlined" 
        value={targetIndex}
        type="number"
        onChange={handleTargetIndexChange}
        inputProps={{
            style: {
              padding:'0 4px 0 12px',  
              height: "40px",
              inputMode: 'numeric', 
              // pattern: '^[-+]?\d*$'
            },
          }}
        sx={{width:160,height:40,minHeight:30,ml:3}} />
     <DatePicker onChange={onChange} 
      allowClear={false}
      disabledDate={disabledDate}
      value={targetDate}
      format={DateFormat}
      style={{border: "1px solid #415e81",background:"#022D5A",
      borderRadius: "4px",width:200,marginLeft:20}}
    />
     <TextField id="outlined-basic" size="small" label="IV Change %" variant="outlined" 
        value={targetIV}
        type="number"
        onChange={handleTargetIVChange}
        inputProps={{
            style: {
              padding:'0 4px 0 12px',  
              height: "40px",
              inputMode: 'numeric', 
            },
          }}
        sx={{width:160,height:40,minHeight:30,ml:3}} />
        <div id="tabs" style={{display:"flex",flexDirection:"row",justifyContent:"flex-end",marginLeft:"60px"}}>
   <Button sx={{width:115,ml:2}}onClick={()=>PopulateLTP()}>Populate LTP</Button>
   <Button variant={isTargetCalculated? "contained":""} sx={{width:115,ml:1}}onClick={()=>resetTarget()}>Reset</Button>
   </div>
         
        
    </div>
    
    <Box sx={{ mt: 4,marginLeft:"24px",display:'flex',flexDirection:"row"}}>
    {greeksResponse!=undefined && greeksResponse.lastDataTime!=undefined?
    <Box>
    <Typography sx={{ml:0,marginTop:"4px",fontSize:"12px"}}>Live Data</Typography>
    <Typography sx={{fontSize:16,ml:0,marginTop:"6px",mr:9}}>{ greeksResponse.lastDataTime.split(" ")[1]}{greeksResponse.lastDataTime.split(" ")[1].length<8?":00":""} </Typography>
       
  </Box>
        :<>
        </>}
    <Box sx={{ width: 500,ml:8}}>
      <Slider
        // aria-label="Always visible"
        defaultValue={timeMinValue}
        value={targetTime.value}
        // getAriaValueText={valuetext}
        onChange={handleTargetTimeChange}
        step={5}
        marks={GREEKS_TABLE_TARGET_TIME}
        // min={timeMinValue}
        min={0}
        max={125}
        // valueLabelDisplay="on"
      />
     
    </Box>
    <Box>
      <Typography sx={{ml:4,marginTop:"4px",fontSize:"12px"}}>Target Time</Typography>
      <Typography sx={{ml:4,marginTop:"6px"}}>{targetTime.time}</Typography>
    </Box>
    <Button variant="contained" disabled={targetDate==undefined || targetDate==""} sx={{ml:5,marginTop:"-8px",height:"40px"}}onClick={()=>CallTargetCalculation()}>CalculateTarget</Button>

    </Box>
    </>:<></>}

    
    {positionLoaded  && !isBasicUser?
    <Box display="flex" justifyContent="flex-start" sx={{mb:1,ml:3,mt:2}} flexDirection="row">
         <Typography sx={{mt:1,mr:0,fontSize:18,width:"140px",mr:1}}>Saved Positions:</Typography>
        
        <FormControl size="small" sx={{ width:"160px",minWidth:'80px',ml:5,mt:0 }}>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={positionData[selectedPositionIndex].value}
          MenuProps={MenuProps}
          sx={{height:"40px",width:"160px",fontSize:14}}
          onChange={(event)=>handlePositionChange(event)}
        >
          {positionData.map((pos,index) => (
          <MenuItem value={pos.value} key={pos.key}>{pos.value}</MenuItem>
          ))}
        </Select>
       
      </FormControl>
      {selectedPositionIndex!=undefined && selectedPositionIndex!=0 && netProfit!=undefined?
        <Box sx={{width:200,ml:4}} display="flex" justifyContent="flex-start">
        <Typography sx={{mt:1,mr:0,fontSize:16,mr:1}}>{netProfit>=0?"Gain:":"Loss:"}&nbsp;</Typography>
        <Typography sx={{mt:1,mr:0,fontSize:16,mr:1,color:netProfit>=0?TABLE_COLORS.GREEN:TABLE_COLORS.RED}}>{(parseFloat(netProfit).toLocaleString(userSettings.data.number_format_locale))}</Typography>
        <Typography sx={{mt:1,mr:0,fontSize:16,ml:0}}>INR</Typography>
        </Box>:
        <Box sx={{width:20}}>
        </Box>
        }
      {selectedPositionIndex!=undefined && selectedPositionIndex!=0?
        <Box sx={{width:380,ml:2}} display="flex" justifyContent="flex-start">
          <Typography sx={{mt:1,mr:0,fontSize:16,mr:0}}>Spot ({posTime}):&nbsp;</Typography>
          <Typography sx={{mt:1,mr:0,fontSize:16,mr:0}}>
            {spotLtp}&nbsp;
            {greeksResponse.spot_ltp-spotLtp>=0?
              <>
                  <ArrowDropUpIcon sx={{color:TABLE_COLORS.GREEN,height:20,width:20,marginTop:"-10px"}}/>
              </>:
              <>
                  <ArrowDropDownIcon sx={{color:TABLE_COLORS.RED,height:20,width:20,marginTop:"-10px"}}/>
              </>
              }
              <Tooltip title="Current Spot LTP - Spot price when position was saved/initiated">
                <span style={{color: greeksResponse.spot_ltp-spotLtp>=0?TABLE_COLORS.GREEN:TABLE_COLORS.RED}} >{(greeksResponse.spot_ltp-spotLtp).toFixed(2)}</span>
              </Tooltip>
          </Typography>
        </Box>:
        <Box sx={{width:20}}>
        </Box>
      }
      <Box sx={{ml:2,mt:0}}>
        <Button sx={{height:40,width:80}} onClick={()=>saveHandler()}>Save</Button>
        <Button disabled={selectedPositionIndex==0} sx={{height:40,width:80,ml:1,mr:1}} onClick={()=>deleteHandler()}>Delete</Button>
        <Button sx={{height:40,width:80}} onClick={()=>resetHandler()}>Clear</Button>
        </Box>
       
      </Box> 
  :<></>
        }   

      {greeksLoaded && tickerHeight!=undefined?
            
      // <div id="optionsTable" style={{marginTop:8,height:`calc(${tableHeight+32+(isBasicUser?56:0)}px)`}}>
      // <div className="ag-theme-alpine-dark" style={{height:`calc(${tableHeight+(isBasicUser?56:0)}px)`, width: `calc(100% - 0px)`}}>
      <div id="greeksTable" style={{marginTop:8,marginBottom:"40px", height:`calc(100vh - 90px)`}}>
      <div className="ag-theme-alpine-dark" style={{height:`calc(100vh - 90px)`, width: `calc(100% - 0px)`}}>
      
           <AgGridReact
            ref={gridRef}
            onGridReady={onGridReady}
            // sideBar={true}
            rowHeight={isBasicUser?110:160}
            headerHeight={44}
            gridOptions={gridOptions}
            rowData={greeksData}
            groupHeaderHeight={30}
            suppressScrollOnNewData={true}
            components={frameworkComponents}
            getRowId={getRowId}
            columnDefs={columnDefs}>
           </AgGridReact>
       </div>
      </div>:
      
      <div  style={{textAlign:"center", width:"100%"}}><CircularProgress sx={{marginTop:"10%"}}/>
      </div>}
    
   
    <FooterToolbar sx={{borderTop: `1px solid ${theme.palette.primaryTheme.shade03}` }} >
        <Container >
        <Typography  variant="copyRight" align="center" >
          {WATERMARK_CONFIG.TABLE_TEXT}
          </Typography>
        </Container>
      </FooterToolbar>
    <Snackbar onClose={handleClose} anchorOrigin={{vertical: 'top',horizontal: 'center'}}  open={open} autoHideDuration={SNACKBAR_AUTO_HIDE_DURATION_SHORT} >
          <Alert  severity={severity} sx={{marginTop:"48px", width:{mobile: '80%',tablet:"70%",laptop:"40%" }}}>
            {msg}
          </Alert>  
       </Snackbar>  

     {openSaveDialog?
     <SavePositionDialog open={true} cancelHandler={cancelSaveHandler}saveHandler={savePosition} name={selectedInstrument+"_"}></SavePositionDialog>    :
     <></>
    }
  {existingOpenSaveDialog?
     <SaveExistingPositionDialog open={true} isTarget={existingTargetFlow} saveAsNew={saveAsNew}cancelHandler={cancelExistingSaveHandler}saveHandler={saveExistingPosition} name={existingPositionName}></SaveExistingPositionDialog>    :
     <></>
    }

{openDeleteDialog?
     <ConfirmActionDialog open={true} cancelHandler={cancelDeleteHandler} saveHandler={deletePosition} 
        name={selectedInstrument+"_"} title={"Delete Position"} description={"Do you want to delete the selected position?"}></ConfirmActionDialog>    :
     <></>
    }

      {showPopup?
         <SubscriptionPopup open={showPopup} proMessage={false} onClose={()=>setShowPopup(false)}></SubscriptionPopup>  :
         <></>
      }  
       {showLoginPopup?
          <LoginModal open={true} onClose={()=>setShowLoginPopup(false)} msg={loginMsg}></LoginModal>  :
         <></>}    
    </div>
  )
}


