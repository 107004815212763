import React, { useState, useEffect, useMemo } from "react";
import { DatePicker } from "antd";
import dayjs from "dayjs";

//MP CC date time 
const TradeDatesPicker = (props) => {
  const [tradeDates, setTradeDates] = useState(props.tradeDates || []);
  const [selectedDate, setSelectedDate] = useState(props.selectedDate||null);

  
  
  // Memoized function to disable specific dates
  const disabledDate = useMemo(() => {
    return (current) => {
      const formattedCurrentDate = current ? current.format("YYYY-MM-DD") : null;
      return !tradeDates.includes(formattedCurrentDate);
    };
  }, [tradeDates]);

  useEffect(()=>{
    console.log("DATETest CC 0",props.selectedDate,selectedDate)
    
   },[])
 useEffect(()=>{
  console.log("DATETest CC 1",props.selectedDate,selectedDate)
  setSelectedDate(props.selectedDate);
 },[props.selectedDate])

  // Handle DatePicker change
  const handleDateChange = (date) => {
    setSelectedDate(date);
    console.log("DATETest CC 2 Selected date:", date ? date.format("DD-MM-YYYY HH:mm") : null);
    props.onChange(date);
  };

  // Handle DatePicker OK button
  const handleOk = (date) => {
    console.log("Date selected on OK:", date ? date.format("DD-MM-YYYY HH:mm") : null);
  };

  return (
    <DatePicker
     
      format="DD-MM-YYYY"
      disabledDate={disabledDate}
      placeholder="Select date"
      onChange={handleDateChange}
      onOk={handleOk}
      value={selectedDate}
      style={{
        border: "1px solid #415e81",
        background: "#022D5A",
        borderRadius: "4px",
        width: 200,
        marginLeft: 40,
      }}
    />
  );
};

export default TradeDatesPicker;
