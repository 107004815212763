import React,{useEffect, useState,useRef} from 'react'
import { Link,Grid,Box, Typography,ListSubheader,TextField,InputAdornment,Input,Tooltip,Select as MUISelect} from '@mui/material'
import { ToolbarFixed } from '../../../layouts/component.js';
import {useTheme,IconButton} from "@mui/material";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
// import Select, { SelectChangeEvent } from '@mui/material/Select';
import {InstrumentsData, TPOData } from '../../../common/utility/configData.js';
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useAxiosWithAuth } from '../../../common/api/hooks/useAxiosWithAuth';
import { URL } from '../../../common/api/urls';
import { display } from '@mui/system';
import SaveIcon from '@mui/icons-material/Save';
import BookmarkAddIcon from '@mui/icons-material/BookmarkAdd';
import Save from '@mui/icons-material/Save';
import { BOOKMARK_CATEGORY_AUTOSAVE,DATE_PICKER_ALLOWED_ROLES,INSTRUMENT_POLLING_TIME,TEXT_MSGS,USER_RESOURCES_LIST } from '../../../common/utility/constant.js';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import BookmarkRemoveIcon from '@mui/icons-material/BookmarkRemove';
import useAuth from '../../../common/hooks/useAuth';
import { Select,ConfigProvider } from 'antd';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { transformAndReverseMap, useQuery } from '../../../common/utility/helperFunc.js';
import { DatePicker,Button } from 'antd';
import dayjs from 'dayjs';
import '../../dashboard/styles/options.scss'
import CancelIcon from '@mui/icons-material/Cancel';
import TradeDatesPicker from './mpccTradeDatePicker.js';



const { Option } = Select;

const containsText = (text, searchText) =>{
  // if(text!=undefined || text!="") return "";
  return text.name.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
}


 const ITEM_HEIGHT = 26;
 const ITEM_PADDING_TOP = 8;
 const MenuProps = {
   PaperProps: {
     style: {
       maxHeight: ITEM_HEIGHT * 10 + ITEM_PADDING_TOP,
       width: 210,
       autoFocus: false
     },
   },
 };

 const MenuPropsTPO = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 10 + ITEM_PADDING_TOP,
      width: 70,
      autoFocus: false
    },
  },
};

 /**
 * 
 * @returns Line chart header container for line chart settings.
 */

function MPChartHeaderSettings(props) {
    const themeMUI=useTheme();
    const { auth } = useAuth();
    const [userSettings,setUserSettings]=useState(props.userSettings)
    const [selectedInstrument, setSelectedInstrument] = React.useState("");
    const [selectedInstrumentData, setSelectedInstrumentData] = React.useState();
    const [instrumentsData,setInstrumentsData]=useState();
    const [selectedTpo, setSelectedTpo] = React.useState();
    const [selectedTpoData, setSelectedTpoData] = React.useState([]);
    const [tpoData,setTPOData]=useState([])
    const [searchText, setSearchText] = React.useState("");
    const searchRef=useRef(searchText);
    const [instrumentLoaded,setInstrumentLoaded]=useState(false)
    const [options,setOptions]=useState();
    const optionsRef=useRef(options);
    const [selectedTPOIndex,setSelectedTPOIndex]=useState();
    const [isStocksAllowed,setIsStocksAllowed]=useState((auth.resources?.find(el=>el==USER_RESOURCES_LIST.MP_STOCKS))?true:false);
    const [tradeDates,setTradeDates]=useState(props.tradeDates)
   
   
    const [cancel,responseData, error, loaded, reset,executeAPI ] = useAxiosWithAuth();
    const [cancelPoll,responseDataPoll, errorPoll, loadedPoll, resetPoll,executeAPIPoll ] = useAxiosWithAuth();
    const inputRef = React.useRef();
    const [isDisabled, setIsDisabled] = useState(true);
    const selectRef = useRef();
    const querySymbol = useQuery("symbol");
    const [selectedDate, setSelectedDate] = useState(null); // Initial default value within range
    const isDatePickerAllowed = auth.role.some(role => DATE_PICKER_ALLOWED_ROLES.includes(role));

   

  // useEffect(() => {
  //   // Set isDisabled to true after 3 seconds
  //   const timeoutId = setTimeout(() => {
  //     setIsDisabled(false);
  //   }, 3000);

  //   // Cleanup the timeout to avoid memory leaks
  //   return () => clearTimeout(timeoutId);
  // }, []); // Empty dependency array ensures the effect runs only once on mount

  // useEffect(() => {
  //   // Set isDisabled to true after 3 seconds
  //   const timeoutId = setTimeout(() => {
  //     setIsDisabled(false);
  //   }, 3000);

  //   // Cleanup the timeout to avoid memory leaks
  //   return () => clearTimeout(timeoutId);
  // }, [selectedInstrument]); // Empty dependency array ensures the effect runs only once on mount


    const autoFocus=()=>{
      const timeout = setTimeout(() => {
        if( inputRef.current)
        inputRef.current.focus();

      }, 100);
  
    }
    // const cleanUP=()=>{}
    //execute the data fetch on the component mount
  useEffect(()=>{
   

    console.log("MP Header Chrtdata--->",props.chartStateData,props.chartStateData.left)
    console.log("default selected instrumnet from params=",selectedInstrument)
    let data={
      "symbol": "NIFTY",
      "instruments": 1,
      "tpoList": 1,
      "additional_instruments": [props.symbol,querySymbol],
      "dateList":1,
      // "dateCount":200
    }
  //   if(props.symbol!=undefined){
  //   const pairedInstrument=props.symbol.split("_");
  //   if(pairedInstrument && pairedInstrument.length>1)
  //    data={
  //     "symbol": "NIFTY",
  //     "instruments": 1,
  //     "tpoList": 1,
  //     "additional_instruments": [props.symbol]
  //   }
  // }
    // "additional_instruments": ["O_NIFTY18200PE20221222_NIFTY18300CE20221222", "O_NIFTY18200PE20221222_NIFTY18200CE20221222"],
    
    executeAPI(URL.MPCHART_UIDATA,"POST",data); 
  },[]);

  /**
 * API response handler  
 */  
   useEffect(() => {
    if(loaded){
      if(responseData!=null){
          console.log("MPCHart UI Data response=",responseData)
          // let processedData=[];
          // for (const key in responseData.instruments) {
          //   // console.log('The value for ' + key + ' is = ' + responseData.instruments[key]);
          //   processedData.push({key:key,value:responseData.instruments[key]})
          // }
         
          // console.log("checkIfSlowInstrument=",checkIfSlowInstrument(selectedInstrument,responseData.slow_instruments));
          // console.log("processedData= ",processedDsata);
          // setSlowInstruments(responseData.slow_instruments);
          // console.log("values= ",responseData.instruments,responseData.tpo_groups,responseData.instruments[0].tpo_group)
          // console.log("values 2:",responseData.tpo_groups[responseData.instruments[0].tpo_group].default_tpo);
          // ,responseData.instruments[0].name)
          // console.time("UIDataTest")
          responseData.instruments = responseData.instruments.map((item) =>
            transformAndReverseMap(item)
          );
          // console.timeEnd("UIDataTest")
          // console.log("UIData test 1=",responseData.instruments)
          let index=0;
          if(props.jsonData!=undefined){
            index = responseData.instruments.findIndex(item => item.instr === props.jsonData.instrument);
            console.log("jsonData in Header",props.jsonData);
            if(index==-1)
            index=0;
          }
          else {
              if(props.symbol!=undefined && props.symbol!=""){
              index = responseData.instruments.findIndex(item => item.instr === props.symbol);
              console.log("result for default inst=",responseData.instruments[index]);
              if(index==-1)
              index=0;
            }
            else if(userSettings!=undefined && userSettings.data!=undefined){
              index = responseData.instruments.findIndex(item => item.name === userSettings.data.default_instrument);
              console.log("Header settings def inst index= ",index);
              if(index==-1)
              index=0;
              
            }
          }
          // console.log("Test MRF=",userSettings.data.tf_tpo_map[responseData.instruments[index].tpo_group],userSettings.data.tf_tpo_map[responseData.instruments[index]],responseData.tpo_groups["DEFAULT"].tpo_list,responseData.tpo_groups[responseData.instruments[index].tpo_group].tpo_list,parseInt(userSettings.data.tf_tpo_map[responseData.instruments[index].tpo_group]!=undefined?userSettings.data.tf_tpo_map[responseData.instruments[index].tpo_group].daily:userSettings.data.tf_tpo_map["DEFAULT"].daily))
          setOptions(responseData.instruments); 
          setInstrumentsData(responseData.instruments);
          setTPOData(responseData.tpo_groups);
          // console.log("Test MRF 1=",responseData.tpo_groups[responseData.instruments[index].tpo_group].default_tpo);
          let tempTPO;
          if(props.jsonData!=undefined)
            tempTPO=props.jsonData.tpo
          // setSelectedTpo(props.jsonData.tpo)
          else if(userSettings!=undefined && userSettings.data!=undefined && userSettings.data.tf_tpo_map[responseData.instruments[index].tpo_group]!=undefined)
            // setSelectedTpo((userSettings.data.tf_tpo_map[responseData.instruments[index].tpo_group]!=undefined?userSettings.data.tf_tpo_map[responseData.instruments[index].tpo_group].daily:userSettings.data.tf_tpo_map["DEFAULT"].daily));
            tempTPO=userSettings.data.tf_tpo_map[responseData.instruments[index].tpo_group].daily;
            // setSelectedTpo(userSettings.data.tf_tpo_map[responseData.instruments[index].tpo_group].daily);
       
          else if(responseData.tpo_groups[responseData.instruments[index].tpo_group]!=undefined)
            // setSelectedTpo(responseData.tpo_groups[responseData.instruments[index].tpo_group]!=undefined?responseData.tpo_groups[responseData.instruments[index].tpo_group].default_tpo:responseData.tpo_groups["DEFAULT"].default_tpo);
            tempTPO=responseData.tpo_groups[responseData.instruments[index].tpo_group].default_tpo
            // setSelectedTpo(responseData.tpo_groups[responseData.instruments[index].tpo_group].default_tpo);

          else
          tempTPO=(userSettings!=undefined && userSettings.data!=undefined?userSettings.data.tf_tpo_map["DEFAULT"].daily:responseData.tpo_groups["DEFAULT"].default_tpo);
            // setSelectedTpo(userSettings!=undefined && userSettings.data!=undefined?userSettings.data.tf_tpo_map["DEFAULT"].daily:responseData.tpo_groups["DEFAULT"].default_tpo)  
          
          setSelectedTpo(tempTPO);

          // console.log("Test MRF tpo list=",responseData.tpo_groups[responseData.instruments[index].tpo_group].tpo_list);
          setSelectedTpoData(responseData.tpo_groups[responseData.instruments[index].tpo_group]!=undefined?responseData.tpo_groups[responseData.instruments[index].tpo_group].tpo_list:responseData.tpo_groups["DEFAULT"].tpo_list);
          setSelectedInstrument(responseData.instruments[index].instr);
          setSelectedInstrumentData(responseData.instruments[index])
          // console.log("responseData.instruments[index]=",responseData.instruments[index],responseData.tpo_groups,responseData.tpo_groups[responseData.instruments[index].tpo_group]);
          // setTradeDates(responseData.tradeDates);
          props.instrumentChange(responseData.instruments[index],responseData.tpo_groups[responseData.instruments[index].tpo_group]?responseData.tpo_groups[responseData.instruments[index].tpo_group]:responseData.tpo_groups["DEFAULT"],tempTPO,responseData.tradeDates);
          setInstrumentLoaded(true);
      }
      else if(error!==null){
        console.log("Error data=",error);
        reset();
    }
    }
  },[loaded,responseData]);

 

  useEffect(()=>{
    if(props.jsonData!=undefined){
      console.log("jsonData in Header=",props.jsonData);
      setSelectedTpo(props.jsonData.tpo);
      props.tpoChange(props.jsonData.tpo);
    }
  },[props.jsonData])

    const handleInstrumentChange = (event,arg,symbol) => {
        // console.log("Instument selected=",event,event.target,userSettings.data,);
        let newSymbol;
        if(symbol!=undefined)
          newSymbol=symbol;
        else
        newSymbol=event;
          // newSymbol=event.target.value; //use this with MUISelect
        
        
          // console.log("newSymbol=",newSymbol,event?.target,event?.target?.value,symbol,arg);  
        // setSelectedInstrument(newSymbol);
        //based on instr name find the instrument object
        let instrument=undefined;
        for(let i=0;i<instrumentsData.length;i++){
          if(instrumentsData[i].instr==newSymbol){
            instrument=instrumentsData[i];
            if(!isStocksAllowed && instrument.resource && instrument.resource=="stocks"){
              props.openSunscriptionModal(true,TEXT_MSGS.MP_CHART_STOCK_RESOURCE_NOT_SUBSCRIBED_DESC);
              return;
            }
            setSelectedInstrumentData(instrument)
          }
        }
        setSelectedInstrument(newSymbol);
        // console.log("instrument selected=",instrument,tpoData);
        // console.log("userSettings.data.tf_tpo_map[tpoData[instrument.tpo_group]].daily=",userSettings.data.tf_tpo_map[tpoData[instrument.tpo_group]].daily)
       
        let tempTPO;
        if(userSettings!=undefined && userSettings.data!=undefined && userSettings.data.tf_tpo_map[instrument.tpo_group]!=undefined)
           // setSelectedTpo(userSettings.data.tf_tpo_map[instrument.tpo_group]!=undefined?userSettings.data.tf_tpo_map[instrument.tpo_group].daily:userSettings.data.tf_tpo_map["DEFAULT"].daily);
           tempTPO=userSettings.data.tf_tpo_map[instrument.tpo_group].daily;
          //  setSelectedTpo(userSettings.data.tf_tpo_map[instrument.tpo_group].daily);
        
            // setSelectedTpo(userSettings.data.tf_tpo_map[instrument.tpo_group].daily);

        else if(tpoData[instrument.tpo_group]!=undefined)
          // setSelectedTpo(tpoData[instrument.tpo_group]!=undefined?tpoData[instrument.tpo_group].default_tpo:tpoData["DEFAULT"].default_tpo);
          tempTPO=tpoData[instrument.tpo_group].default_tpo
          // setSelectedTpo(tpoData[instrument.tpo_group].default_tpo);
         
        else 
        tempTPO=(userSettings!=undefined && userSettings.data!=undefined ?userSettings.data.tf_tpo_map["DEFAULT"].daily:tpoData["DEFAULT"].default_tpo) ;
          // setSelectedTpo(userSettings!=undefined && userSettings.data!=undefined ?userSettings.data.tf_tpo_map["DEFAULT"].daily:tpoData["DEFAULT"].default_tpo)  

          props.instrumentChange(instrument,tpoData[instrument.tpo_group]?tpoData[instrument.tpo_group]:tpoData["DEFAULT"],tempTPO,tradeDates);
        setSelectedTpo(tempTPO);
        setSelectedTpoData(tpoData[instrument.tpo_group]!=undefined? tpoData[instrument.tpo_group].tpo_list:tpoData["DEFAULT"].tpo_list);
        // setIsDisabled(true);
          
        // const isSlowInstrumentSelected=checkIfSlowInstrument(event.target.value,slowInstruments);
        // props.instrumentChange(event.target.value,isSlowInstrumentSelected);        
    };

    useEffect(() => {
      //set the ref to current state
      // stateRef.current = state;
      
      // console.log("Live use effect called=",stateRef.current,stateRef.current.refreshInterval);
      const timeout = setInterval(() => {
        //if instrument is live, do fetch data based on user role else keep polling every 1min 
        let data={
          "symbol": "NIFTY",
          "instruments": 1,
          "tpoList": 1,
          "additional_instruments": [props.symbol,querySymbol]
        }
          
        executeAPIPoll(URL.MPCHART_UIDATA,"POST",data); 
      
      }, INSTRUMENT_POLLING_TIME);

     
    
      return () => {
        // just to clear the timeout when component unmounts
        clearInterval(timeout);
       
      };
    }, []);


  /**
 * API response handler  
 */  
   useEffect(() => {
    if(loadedPoll){
      if(responseDataPoll!=null){
          // console.log("MPCHart UI Data response=",responseData)
          // setOptions
          // let newData= JSON.parse(JSON.stringify(responseDataPoll.instruments[0]));
          // newData.instr="NIFTY1"
          // newData.name="Nifty Test"
          // responseDataPoll.instruments.push(newData)
          // console.log("Polling newdata=",responseDataPoll.instruments)
          // if(searchRef.current=="" || searchRef.current==undefined)
          //   setOptions(responseDataPoll.instruments); 
          //  else{
          //   setOptions(responseDataPoll.instruments);
          //     // don't do an
          //     // const temp=responseDataPoll.instruments.filter((option) => containsText(option, searchText))
          //     // setOptions(temp);
          //   }
          responseDataPoll.instruments = responseDataPoll.instruments.map((item) =>
            transformAndReverseMap(item)
          );
          setOptions(responseDataPoll.instruments); 
      }
      else if(errorPoll!==null){
        console.log("Error data=",errorPoll);
        resetPoll();
    }
    }
  },[loadedPoll,responseDataPoll]);


    const handleTPOChange = (event) => {
      console.log("TPO selected=",event.target);
      setSelectedTpo(event.target.value);
      props.tpoChange(Number(event.target.value));
      
  };
  
  const setNextTPO=()=>{
    console.log("next tpo, selectedtpoData=",selectedTpoData);
    let selectedIndex=-1;
    selectedTpoData.forEach((tpo,index)=>{
      if(tpo==selectedTpo){
        selectedIndex=index;
      }
    })
    if(selectedIndex!=selectedTpoData.length-1)
      selectedIndex=selectedIndex+1;
    else{
      // selectedIndex=0;
      return;
    }
    
      console.log("selected index val=",selectedIndex,selectedTpoData[selectedIndex])
      setSelectedTpo(selectedTpoData[selectedIndex]);
      props.tpoChange(Number(selectedTpoData[selectedIndex]));
  }

  const setPreviousTPO=()=>{
    let selectedIndex=-1;
    selectedTpoData.forEach((tpo,index)=>{
      if(tpo==selectedTpo){
        selectedIndex=index;
      }
    })
    if(selectedIndex!=0)
      selectedIndex=selectedIndex-1;
    else{
      // selectedIndex=selectedTpoData.length-1;
      return;
    }
      
    
    setSelectedTpo(selectedTpoData[selectedIndex]);
    props.tpoChange(Number(selectedTpoData[selectedIndex]));
  }

  // useEffect(()=>{
  //   console.log("searchText=", searchText );
  //   if(instrumentsData!=undefined){
  //     if(searchText=="")
  //     setOptions(instrumentsData)
  //     else{
  //       const temp=instrumentsData.filter((option) => containsText(option, searchText))
  //       setOptions(temp);
  //     }
  //   }
  // },[searchText])

  // useEffect(()=>{
   
  //   console.log("searchText=", searchText );
  //   searchRef.current=searchText;
  //   // const timeout = setTimeout(() => {
  //   //   if (selectRef.current) {
  //   //     // selectRef.current.scrollTo(0, 0);
  //   //     selectRef.current.scrollTo({ index: 0 });
  //   //   }

  //   // }, 100);

  //   if(instrumentsData!=undefined){
  //     if(searchText==""){
  //     setOptions(instrumentsData)
  //     // const timeout = setTimeout(() => {
  //     //   if( inputRef.current)
  //     //   inputRef.current.focus();

  //     // }, 100);
  //   }

    
  //     else{
  //       console.log("Test instr filter",searchRef.current,searchText);
  //       const temp=instrumentsData.filter((option) => containsText(option, searchRef.current))
       
  //       setOptions(temp);
  //     }
  //   }
  //   // return () => clearTimeout(timeout);
  // },[searchText])

  useEffect(()=>{
    console.log("searchText=", searchText );
    searchRef.current=searchText;
       const timeout = setTimeout(() => {
      if (selectRef.current) {
        // selectRef.current.scrollTo(0, 0);
        if(instrumentsData!=undefined && (searchRef.current==undefined ||  searchRef.current=="")){
          let index=instrumentsData.findIndex(item=>item.instr==selectedInstrument)
          if(index==-1)
          index=0
          selectRef.current.scrollTo({ index: index });
        }
        else selectRef.current.scrollTo({ index: 0 });
      }

    }, 100);
    return () => clearTimeout(timeout);
  },[searchText])

  useEffect(()=>{
    console.log("test instr options useEffect=", options );
    optionsRef.current=options;
    
  },[options])


  useEffect(()=>{
    //check if tpo is present with the tpo list
    // if(selectedTpoData.includes(props.tpo))
      setSelectedTpo(props.tpo);
    // else  
      // props.tpoChange(selectedTpo);
  },[props.tpo])

  //keep track of current selected tpo index to enable + - buttons for tpo
  useEffect(()=>{
    console.log("MP Header selected tpo changed=",selectedTpo,selectedTpoData)
    if(selectedTpo!=undefined && selectedTpoData)
    selectedTpoData.forEach((tpo,index)=>{
      if(tpo==selectedTpo){
       setSelectedTPOIndex(index);
      }
    })
  },[selectedTpo])

  //default MP chart view from bookmark back
  const goBackToMP=()=>{
    let index = instrumentsData.findIndex(item => item.name === userSettings.data.default_instrument);
    console.log("Header settings def inst index= ",index);
    if(index==-1)
    index=0;

    const symbol=instrumentsData[index].instr;
    props.loadDefaultMPChart();
    handleInstrumentChange(undefined,undefined,symbol);
   
    

  }

  const clearAutosave=()=>{
    props.clearAutosave();
  }

  useEffect(()=>{
    if(props.reloadInstrument!=undefined){
      handleInstrumentChange(undefined,undefined,props.reloadInstrument);

    }
  },[props.reloadInstrument])

  useEffect(()=>{
    console.log("props.bookmarkData header selectedTimeFrame===",props.bookmarkData,props.selectedTimeFrame)
  },[props.selectedTimeFrame,props.bookmarkData])


  const handleSearch = (value) => {
    // Handle the search logic here
    console.log('Searching for:', value);
    // Perform your search operation and update the options accordingly
  };

  const handleDropdownVisibleChange = (visible) => {
    // Close and reopen the dropdown to reset scroll position
    if (visible) {
      setSearchText(''); // Reset search value
    }
  };

  const disabledDate = React.useMemo(() => {
    return (current) => {
      const formattedCurrentDate = current.format("YYYY-MM-DD");
      return !tradeDates.includes(formattedCurrentDate);
    };
  }, []);

  const onChange = (date) => {
    console.log("DateTest 1 MP Header date=",date);
    setSelectedDate(date);
    
    if(props.changeSelectedDateTime)
    props.changeSelectedDateTime(date);
  };

 
  return (
    <ConfigProvider
      theme={{
        // algorithm: [theme.darkAlgorithm, theme.compactAlgorithm],
        components: {
          
          Select: {
            colorPrimary: '#0d203f',
            colorBgContainer:"#0000ff",
            optionActiveBg:"#22314E",
            colorText:"#FFFFFF",
            optionSelectedBg:"#35445E",
            optionSelectedColor:"#FFFFFF",
            selectorBg:"#2f5cdb",
            colorTextPlaceholder:"#ffffff",
            colorIcon:"#ff0000",
            colorTextDisabled:"#899499"
            // algorithm: true, // Enable algorithm
          },
          
        },
      }}
    >
    <div>
        <ToolbarFixed sx={{borderTop: `2px solid ${themeMUI.palette.primaryTheme.shade03}`,borderRight: `2px solid ${themeMUI.palette.primaryTheme.shade03}`,paddingLeft:0,paddingRight:4,left:props.chartStateData.left, width:props.chartStateData.width}}>
        {instrumentLoaded && options!=undefined && optionsRef.current?
        <Grid  container sx={{height:"100%"}} >
         
            <Grid item mobile={5} tablet={6} laptop={6} >
              {props.bookmarkData==undefined || props.bookmarkData.category==BOOKMARK_CATEGORY_AUTOSAVE?
              <Box display="flex"  justifyContent="center" alignContent="center" alignItems="center" sx={{height:"100%", borderRight:`1px solid ${themeMUI.palette.primaryTheme.shade03}`}}>
            
                <FormControl variant="standard" size="small" sx={{ minWidth:"80%" }}>
         
                    {/* <Select
                    disabled={isDisabled}
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        value={searchText==""?selectedInstrument:searchText}
                        onChange={handleInstrumentChange}
                        onOpen={autoFocus}
                        label={selectedInstrument}
                        MenuProps={MenuProps}
                        onClose={() => setSearchText("")}
                        // This prevents rendering empty string in Select's value
                        // if search text would exclude currently selected option.
                        // renderValue={() => selectedInstrument}
                        >
                        
                        <ListSubheader>
                      
                            <TextField
                            size="small"
                            // Autofocus on textfield
                            autoFocus
                            // ref={focusUsernameInputField}
                            inputRef={inputRef}
                            variant='standard'
                            placeholder="Type to search..."
                            fullWidth
                            InputProps={{
                                startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                                )
                            }}
                            onChange={(e) => {setSearchText(e.target.value)
                            inputRef.current.focus();
                            }
                            }
                            onKeyDown={(e) => {
                              console.log("key down 1");
                                if (e.key !== "Escape") {
                                  console.log("key down 2");
                                // Prevents autoselecting item while typing (default Select behaviour)
                                e.stopPropagation();
                                }
                            }}
                            />
                        
                        </ListSubheader>  
                        {console.log("test instr options before rendering=",options,optionsRef.current, searchRef?.current)}
                        
                        {optionsRef.current.map((item,index) => (
                          
                          <MenuItem key={item.instr} value={item.instr} onKeyDown={(e) => e.stopPropagation()}>{item.name}</MenuItem>
                        ))}
                    </Select> */}
                   
                   <Select
                        variant="borderless"
                        suffixIcon={<ArrowDropDownIcon  style={{ color: 'white' }} />}
                        ref={selectRef}
                        showSearch
                        style={{
                          // width: 200,
                          height:24,
                          maxHeight:24,
                          marginTop:-4,
                          color:"#FFFFFF"
                        }}
                       
                        placeholder="Search Instrument"
                        optionFilterProp="children"
                        defaultValue={"NIFTY"}
                        value={selectedInstrument}
                        dropdownStyle={{ backgroundColor: '#0d203f',color:'#00ff00',minWidth:"240px"}}
                        // onChange={handleSelectChange}
                        onChange={handleInstrumentChange}
                        onSearch={(value)=>setSearchText(value)}
                        // onDropdownVisibleChange={handleDropdownVisibleChange}
                        // filterOption={(input, option) => (option?.name ?? "").includes(input)}
                        // filterSort={(optionA, optionB) =>
                        //   (optionA?.children ?? '').toLowerCase().localeCompare((optionB?.children ?? '').toLowerCase())
                        // // console.log("option=== A B=",optionA,optionB,inputValue)
                        //   // optionA.children.toLowerCase().indexOf(inputValue.toLowerCase()) -
                        //   // optionB.children.toLowerCase().indexOf(inputValue.toLowerCase())
                        // }
                        filterOption={(input, option) => {
                          const words = input.trim().toLowerCase().split(' ');
                          return words.every(word => option.props.children.toLowerCase().includes(word));
                          
                          // return (
                          //   // option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                          //   option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          // );
                        }}
                      >
                      {options.map((item) => (
                        <Option key={item.instr} value={item.instr} disabled={props.disabled} style={{fontSize:"12px"}}>

                          {item.name}
                        </Option>
                      ))}
                </Select>      
   

                </FormControl>
              </Box>:
               <Box sx={{textAlign: 'center', display: 'flex', flexDirection: "row",alignItems:"center", justifyContent: "flex-start" ,height:"100%", borderRight:`1px solid ${themeMUI.palette.primaryTheme.shade03}`}}>
                <Tooltip title="Back to Market Profile Chart">
            <IconButton
             sx={{
              marginLeft:3,
              width: 20,
              height: 20}}
              onClick={() => goBackToMP()}
              >
              <ArrowBackIcon sx={{ width: "24px", height: "24px" }} />
            </IconButton>
          </Tooltip>
              <Typography variant='lableTextSettings' sx={{ml:3}}>{props.bookmarkData.name}:&nbsp;({props.bookmarkData.data.instrument})</Typography>
              </Box>}
            </Grid>
             
            <Grid item mobile={7} tablet={6} laptop={6} >
              {!props.showTable?
            <Box display="flex"  justifyContent="space-between" alignContent="center" alignItems="center" sx={{width:"100%",overflowX:'auto',minWidth:"200px"}} >
            <Box display="flex"  justifyContent="flex-start" alignContent="center" alignItems="center" >
                <Typography variant='lableTextSettings' sx={{fontSize:12,ml:2}}>{"TPO "}</Typography>
                <FormControl variant="standard" size="small" sx={{ml:1, minWidth:"70px",height:"58%" }}>
                    <MUISelect
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        value={selectedTpo}
                        onChange={handleTPOChange}
                        label="TPO Size"
                        sx={{textAlign:"center",width:70,paddingTop:"4px"}}
                        MenuProps={MenuPropsTPO}
                        >
                        {selectedTpoData.map((item,index) => (
                            <MenuItem key={item+index} value={item}>{item}</MenuItem>
                        ))}
                    </MUISelect>
                </FormControl>
       
        <IconButton
            onClick={() => setPreviousTPO()}
            disabled={selectedTPOIndex==0}
            sx={{
              marginLeft:2,
              width: 20,
              height: 20,
              borderRadius: 0,
              border: "0.4px solid",
              borderColor: "primaryTheme.shade10"
            }}
            aria-label='add'>

            <RemoveIcon sx={{
              width: 20,
              height: 20,
            }}
            />
            </IconButton>
            <IconButton
           onClick={() => setNextTPO()}
           disabled={selectedTPOIndex==selectedTpoData.length-1}
            sx={{
              marginLeft:1,
              width: 20,
              height: 20,
              borderRadius: 0,
              border: "0.4px solid",
              borderColor: "primaryTheme.shade10"
            }}
            aria-label='add'>

            <AddIcon sx={{
              width: 20,
              height: 20,
            }}
            />
        </IconButton>
        <Box display="flex"  justifyContent="flex-start" alignContent="center" alignItems="center" >
     
     {/* <DatePicker
       
       format="DD-MM-YYYY"
       disabledDate={disabledDate}
       placeholder="Select date"
       onChange={onChange}
      //  onOk={onOk} // Validate when the OK button is clicked
      // renderExtraFooter={() => (
      //   <Button type="primary" disabled={!selectedDate}>
      //     OK
      //   </Button>
      // )}
       value={selectedDate}
       style={{
         border: "1px solid #415e81", background: "#022D5A",
         borderRadius: "4px", width: 140, marginLeft: 40
       }}
     /> */}
     
     {/* Hide the datepicker if bookmark is opened */}
     {isDatePickerAllowed &&(!props.bookmarkData ||props?.bookmarkData?.category==BOOKMARK_CATEGORY_AUTOSAVE)?
     <>
     <TradeDatesPicker tradeDates={tradeDates} onChange={onChange} selectedDate={selectedDate}/>
      {selectedDate?
     <Tooltip title={"Reset Chart"}>
       <CancelIcon sx={{ ml: 1, cursor: 'pointer' }} onClick={() => onChange(null)} />
     </Tooltip>:
     <></>
      }
     </>:
     <></>
      }
</Box>
        
              </Box>
              <Box display="flex"  justifyContent="flex-end" alignContent="center" alignItems="center">
              {!selectedDate && selectedInstrumentData.autosave && props.bookmarkData!=undefined && props.bookmarkData.category==BOOKMARK_CATEGORY_AUTOSAVE && props.selectedTimeFrame=="daily"?
              
                  <Tooltip title={"Clear autosaved bookmark"}>
                    <BookmarkRemoveIcon sx={{ml:1,cursor:'pointer'}} onClick={()=>clearAutosave()}></BookmarkRemoveIcon>
                  </Tooltip>
                   :<></>
                }   

                {/* incase custom bookmark is open by non pro user */}
                {props.bookmarkData!=undefined && props.bookmarkData.category!=BOOKMARK_CATEGORY_AUTOSAVE && !props.proUser?
                <></>:
                <>
                    {(props.levelData[0]==-1 || props.levelData[1]==-1)&& !selectedDate && (selectedInstrumentData.autosave || props.bookmarkData!=undefined)&&(props.selectedTimeFrame=="daily"||(props.bookmarkData!=undefined &&props.bookmarkData.category!=BOOKMARK_CATEGORY_AUTOSAVE))
                    ?
                      <>
                      {props.isDirty &&  (!props.bookmarkData || props.bookmarkData?.category==BOOKMARK_CATEGORY_AUTOSAVE ||  props.bookmarkData?.user_email==auth?.email_id)?
                      <Tooltip title={props.bookmarkData==undefined || props.bookmarkData.category==BOOKMARK_CATEGORY_AUTOSAVE?"Save":"Update"}>
                        <Save  sx={{ml:1,cursor:'pointer'}} onClick={()=>props.saveChart()}></Save>
                      </Tooltip>:
                      <Save  sx={{ml:1,color:"#777"}}></Save>}
                      
                      </>
                      :<></>
                    } 
                </>
                }       
                {props.proUser && !selectedDate?
                  <Tooltip title="Save as">
                    <BookmarkAddIcon sx={{ml:1,cursor:'pointer'}}onClick={()=>props.saveBookmark()}></BookmarkAddIcon>
                  </Tooltip>:
                  <></>
                }
              </Box>
              </Box>:
              <></>
              } 
            </Grid>
            
            {/* // <Grid item mobile={4} tablet={4} laptop={4} >
            //<Box display="flex"  justifyContent="center" alignContent="center" alignItems="center" sx={{height:"100%"}}>
              //  <Typography variant='lableTextSettings'>{"Set Level  "}</Typography>
                //<Input placeholder="Low Level" sx={{fontSize:"12px",marginTop:"2px",marginLeft:"8px",width:"70px"}} />
                //<Typography variant='lableTextSettings'>{"  -  "}</Typography>
                //<Input placeholder="High Level" sx={{fontSize:"12px",marginTop:"2px",marginLeft:"8px",width:"70px"}} />
           // </Box>
            //</Grid>  */}
                      
          </Grid>
          :<></>}
          
          
        </ToolbarFixed>
       
    </div>
    </ConfigProvider>
  )
}

export default MPChartHeaderSettings
