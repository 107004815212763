import React,{useEffect, useState,useRef} from 'react'
import { Link,Grid,Box, Typography,ListSubheader,TextField,InputAdornment,Input,Tooltip,Select as MUISelect} from '@mui/material'
import { ToolbarFixed } from '../../../layouts/component.js/index.js';
import {useTheme,IconButton} from "@mui/material";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
// import Select, { SelectChangeEvent } from '@mui/material/Select';
import {InstrumentsData, TPOData } from '../../../common/utility/configData.js';
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useAxiosWithAuth } from '../../../common/api/hooks/useAxiosWithAuth';
import { URL } from '../../../common/api/urls';
import { display } from '@mui/system';
import SaveIcon from '@mui/icons-material/Save';
import BookmarkAddIcon from '@mui/icons-material/BookmarkAdd';
import Save from '@mui/icons-material/Save';
import { BOOKMARK_CATEGORY_AUTOSAVE,INSTRUMENT_POLLING_TIME,TEXT_MSGS,USER_RESOURCES_LIST } from '../../../common/utility/constant.js';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import BookmarkRemoveIcon from '@mui/icons-material/BookmarkRemove';
import useAuth from '../../../common/hooks/useAuth';
import { Select,ConfigProvider } from 'antd';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { transformAndReverseMap, useQuery } from '../../../common/utility/helperFunc.js';

const { Option } = Select;

const containsText = (text, searchText) =>{
  // if(text!=undefined || text!="") return "";
  return text.name.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
}


 const ITEM_HEIGHT = 26;
 const ITEM_PADDING_TOP = 8;
 const MenuProps = {
   PaperProps: {
     style: {
       maxHeight: ITEM_HEIGHT * 10 + ITEM_PADDING_TOP,
       width: 210,
       autoFocus: false
     },
   },
 };

 const MenuPropsTPO = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 10 + ITEM_PADDING_TOP,
      width: 70,
      autoFocus: false
    },
  },
};

 /**
 * 
 * @returns Line chart header container for line chart settings.
 */

function CandlestickHeader(props) {
    const theme=useTheme();
    const { auth } = useAuth();
    const [userSettings,setUserSettings]=useState(props.userSettings)
    const [selectedInstrument, setSelectedInstrument] = React.useState("");
    const [selectedInstrumentData, setSelectedInstrumentData] = React.useState();
    const [instrumentsData,setInstrumentsData]=useState();
    const [selectedTpo, setSelectedTpo] = React.useState();
    const [selectedTpoData, setSelectedTpoData] = React.useState([]);
    const [tpoData,setTPOData]=useState([])
    const [searchText, setSearchText] = React.useState("");
    const searchRef=useRef(searchText);
    const [instrumentLoaded,setInstrumentLoaded]=useState(false)
    const [options,setOptions]=useState();
    const [selectedTPOIndex,setSelectedTPOIndex]=useState();
    const [isStocksAllowed,setIsStocksAllowed]=useState((auth.resources.find(el=>el==USER_RESOURCES_LIST.OF_STOCKS))?true:false)
    const [isIndexAllowed,setIsIndexAllowed]=useState((auth.resources.find(el=>el==USER_RESOURCES_LIST.OF_INDEX))?true:false)
   
    const displayedOptions = React.useMemo(
      () => {
        // console.log("data, search text=",instrumentsData,searchText);
        if(instrumentsData!=undefined)
        instrumentsData.filter((option) => containsText(option, searchText))
        // else
        // return []
      },
      [searchText,instrumentsData]
    );
    const [cancel,responseData, error, loaded, reset,executeAPI ] = useAxiosWithAuth();
    const [cancelPoll,responseDataPoll, errorPoll, loadedPoll, resetPoll,executeAPIPoll ] = useAxiosWithAuth();
    const [cancelOF,responseDataOF, errorOF, loadedOF, resetOF,executeAPIOF ] = useAxiosWithAuth();
    const inputRef = React.useRef();
    const selectRef = useRef();
    const querySymbol = useQuery("symbol");

    const autoFocus=()=>{
      const timeout = setTimeout(() => {
        if( inputRef.current)
        inputRef.current.focus();

      }, 100);
  
    }
    // const cleanUP=()=>{}
    //execute the data fetch on the component mount
  useEffect(()=>{
   

    console.log("MP Header Chrtdata--->",props.chartStateData,props.chartStateData.left)
    console.log("default selected instrumnet from params=",selectedInstrument)
    let data={
      "symbol": "NIFTY",
      "instruments": 1,
      "tpoList": 1,
      "additional_instruments": [props.symbol,querySymbol]
    }
 
    executeAPI(URL.MPCHART_UIDATA,"POST",data); 

  //   let tempData={
  //     "symbol": "NIFTY",
  //     "nProf": 1,
  //     "liveData": true,
  //     "liveOnly": false,
  //     "startDate": "",
  //     "endDate": "",
  //     "tf": "1",
  //     "contracts": true
  // }
  
  // executeAPIOF(URL.OF_CHART,"POST",tempData);
  },[]);

   /**
 * API response handler  
 */  
   useEffect(() => {
    if(loadedOF){
      if(responseDataOF!=null){
        console.log("OFdata header=",JSON.parse(JSON.stringify(responseDataOF)))
        let test=JSON.parse(JSON.stringify(responseDataOF))
        console.log("OFdata header=",test.dateList)
      }
      else if(errorOF!==null){
        console.log("Error data=",errorOF);
        resetOF();
    }
    }
  },[loadedOF,responseDataOF]);


  /**
 * API response handler  
 */  
   useEffect(() => {
    if(loaded){
      if(responseData!=null){
        responseData.instruments = responseData.instruments.map((item) =>
          transformAndReverseMap(item)
        );
        responseData.instruments= responseData.instruments.filter(item => item.of===true);
          console.log("OF chart UI Data response=",responseData)
        
          let index=0;
          if(props.jsonData!=undefined){
            index = responseData.instruments.findIndex(item => item.instr === props.jsonData.instrument);
            console.log("jsonData in Header",props.jsonData);
            if(index==-1)
            index=0;
          }
          else {
              if(props.symbol!=undefined && props.symbol!=""){
              index = responseData.instruments.findIndex(item => item.instr === props.symbol);
              console.log("result for default inst=",responseData.instruments[index]);
              if(index==-1)
              index=0;
            }
            else if(userSettings!=undefined && userSettings.data!=undefined){
              let default_instrument=userSettings.data.default_instrument_candle?userSettings.data.default_instrument_candle:userSettings.data.default_instrument
              index = responseData.instruments.findIndex(item => item.name === userSettings.data.default_instrument);
              console.log("Header settings def inst index= ",index);
              if(index==-1)
              index=0;
              
            }
          }
          setOptions(responseData.instruments); 
          setInstrumentsData(responseData.instruments);
          setTPOData(responseData.tpo_groups);
          // console.log("OFSETTINGS HEader 1 ",userSettings.data.tf_tpo_map_of[responseData.instruments[index].tpo_group])
          if(props.jsonData!=undefined)
            setSelectedTpo(props.jsonData.tpo)
            // setSelectedTpo(0.2)
          else if(userSettings!=undefined && userSettings.data!=undefined){
          // setSelectedTpo(0.2)
            // setSelectedTpo(userSettings.data.tf_tpo_map[responseData.instruments[index].tpo_group]!=undefined?userSettings.data.tf_tpo_map[responseData.instruments[index].tpo_group].daily:userSettings.data.tf_tpo_map["DEFAULT"].daily);

            // setSelectedTpo(responseData.tpo_groups[responseData.instruments[index].tpo_group]!=undefined && responseData.tpo_groups[responseData.instruments[index].tpo_group].of_tpo_list?responseData.tpo_groups[responseData.instruments[index].tpo_group].of_default_tpo:responseData.tpo_groups["DEFAULT"].of_default_tpo);
            let defaultTPO=responseData.tpo_groups[responseData.instruments[index].tpo_group]!=undefined && responseData.tpo_groups[responseData.instruments[index].tpo_group].of_tpo_list?responseData.tpo_groups[responseData.instruments[index].tpo_group].of_default_tpo:responseData.tpo_groups["DEFAULT"].of_default_tpo;
            
            let newTPO=userSettings.data.tf_tpo_map_of!=undefined&&userSettings.data.tf_tpo_map_of[responseData.instruments[index].tpo_group]!=undefined?userSettings.data.tf_tpo_map_of[responseData.instruments[index].tpo_group].min3:defaultTPO
            // console.log("OFSETTINGS HEader 2 ",userSettings.data.tf_tpo_map_of[responseData.instruments[index].tpo_group],defaultTPO,newTPO)
            setSelectedTpo(userSettings.data.tf_tpo_map_of!=undefined &&userSettings.data.tf_tpo_map_of[responseData.instruments[index].tpo_group]!=undefined?userSettings.data.tf_tpo_map_of[responseData.instruments[index].tpo_group].min3:defaultTPO);
          }
            else 
          // setSelectedTpo(0.2)
            setSelectedTpo(responseData.tpo_groups[responseData.instruments[index].tpo_group]!=undefined && responseData.tpo_groups[responseData.instruments[index].tpo_group].of_tpo_list?responseData.tpo_groups[responseData.instruments[index].tpo_group].of_default_tpo:responseData.tpo_groups["DEFAULT"].of_default_tpo);
         
          setSelectedTpoData(responseData.tpo_groups[responseData.instruments[index].tpo_group]!=undefined && responseData.tpo_groups[responseData.instruments[index].tpo_group].of_tpo_list?responseData.tpo_groups[responseData.instruments[index].tpo_group].of_tpo_list:responseData.tpo_groups["DEFAULT"].of_tpo_list);
          setSelectedInstrument(responseData.instruments[index].instr);
          setSelectedInstrumentData(responseData.instruments[index])
          console.log("responseData.instruments[index]=",responseData.instruments[index],responseData.tpo_groups,responseData.tpo_groups[responseData.instruments[index].tpo_group]);
          props.instrumentChange(responseData.instruments[index],(responseData.tpo_groups[responseData.instruments[index].tpo_group] && responseData.tpo_groups[responseData.instruments[index].tpo_group].of_tpo_list)?responseData.tpo_groups[responseData.instruments[index].tpo_group]:responseData.tpo_groups["DEFAULT"]);
          setInstrumentLoaded(true);
      }
      else if(error!==null){
        console.log("Error data=",error);
        reset();
    }
    }
  },[loaded,responseData]);

 

  useEffect(()=>{
    if(props.jsonData!=undefined){
      console.log("jsonData in Header=",props.jsonData);
      setSelectedTpo(props.jsonData.tpo);
      props.tpoChange(props.jsonData.tpo);
    }
  },[props.jsonData])

    const handleInstrumentChange = (event,arg,symbol) => {
        // console.log("Instument selected=",event,event.target,userSettings.data,);
        let newSymbol;
        if(symbol!=undefined)
          newSymbol=symbol;
        else
        newSymbol=event;
          // newSymbol=event.target.value;
        console.log("newSymbol=",newSymbol,event?.target,event?.target?.value,symbol,arg);  
        // setSelectedInstrument(newSymbol);
        //based on instr name find the instrument object
        let instrument=undefined;
        for(let i=0;i<instrumentsData.length;i++){
          if(instrumentsData[i].instr==newSymbol){
            instrument=instrumentsData[i];
            if(!isStocksAllowed && instrument.resource && instrument.resource=="stocks"){
              props.openSunscriptionModal(true,TEXT_MSGS.OF_CHART_STOCKS_RESOURCE_NOT_SUBSCRIBED_DESC);
              return;
            }else if(!isIndexAllowed && instrument.resource && instrument.resource=="index"){
              props.openSunscriptionModal(true,TEXT_MSGS.OF_CHART_INDEX_RESOURCE_NOT_SUBSCRIBED_DESC);
              return;
            }
            setSelectedInstrumentData(instrument)
          }
        }
        setSelectedInstrument(newSymbol);
        // console.log("OFSETTINGS Header instrument selected=",instrument,tpoData,userSettings.data.tf_tpo_map_of[instrument.tpo_group]);
        // console.log("userSettings.data.tf_tpo_map[tpoData[instrument.tpo_group]].daily=",userSettings.data.tf_tpo_map[tpoData[instrument.tpo_group]].daily)
        props.instrumentChange(instrument,tpoData[instrument.tpo_group] && tpoData[instrument.tpo_group].of_tpo_list?tpoData[instrument.tpo_group]:tpoData["DEFAULT"]);
        if(userSettings!=undefined && userSettings.data!=undefined)
        // setSelectedTpo(userSettings.data.tf_tpo_map[instrument.tpo_group]!=undefined?userSettings.data.tf_tpo_map[instrument.tpo_group].daily:userSettings.data.tf_tpo_map["DEFAULT"].daily);
        // setSelectedTpo(tpoData[instrument.tpo_group]!=undefined && tpoData[instrument.tpo_group].of_tpo_list?tpoData[instrument.tpo_group].of_default_tpo:tpoData["DEFAULT"].of_default_tpo);
        setSelectedTpo(userSettings.data.tf_tpo_map_of[instrument.tpo_group]!=undefined?userSettings.data.tf_tpo_map_of[instrument.tpo_group].min3:tpoData[instrument.tpo_group]!=undefined && tpoData[instrument.tpo_group].of_tpo_list?tpoData[instrument.tpo_group].of_default_tpo:tpoData["DEFAULT"].of_default_tpo);
 
            // setSelectedTpo(userSettings.data.tf_tpo_map[instrument.tpo_group].daily);

        else 
        setSelectedTpo(tpoData[instrument.tpo_group]!=undefined && tpoData[instrument.tpo_group].of_tpo_list?tpoData[instrument.tpo_group].of_default_tpo:tpoData["DEFAULT"].of_default_tpo);
      
        
        setSelectedTpoData(tpoData[instrument.tpo_group]!=undefined && tpoData[instrument.tpo_group].of_tpo_list? tpoData[instrument.tpo_group].of_tpo_list:tpoData["DEFAULT"].of_tpo_list);
          
        // const isSlowInstrumentSelected=checkIfSlowInstrument(event.target.value,slowInstruments);
        // props.instrumentChange(event.target.value,isSlowInstrumentSelected);        
    };

    useEffect(() => {
      const timeout = setInterval(() => {
        let data={
          "symbol": "NIFTY",
          "instruments": 1,
          "tpoList": 1,
         "additional_instruments": [props.symbol,querySymbol]
        }
          
        executeAPIPoll(URL.MPCHART_UIDATA,"POST",data); 
      
      }, INSTRUMENT_POLLING_TIME);

      return () => {
        // just to clear the timeout when component unmounts
        clearInterval(timeout);
       
      };
    }, []);


  /**
 * API response handler  
 */  
   useEffect(() => {
    if(loadedPoll){
      if(responseDataPoll!=null){
        responseDataPoll.instruments = responseDataPoll.instruments.map((item) =>
          transformAndReverseMap(item)
        );
        responseDataPoll.instruments= responseDataPoll.instruments.filter(item => item.of===true);
        setOptions(responseDataPoll.instruments); 
          // console.log("MPCHart UI Data response=",responseData)
          // if(searchText=="")
          //   setOptions(responseDataPoll.instruments); 
          //  else{
          //     const temp=responseDataPoll.instruments.filter((option) => containsText(option, searchText))
          //     setOptions(temp);
          //   }
      }
      else if(errorPoll!==null){
        console.log("Error data=",errorPoll);
        resetPoll();
    }
    }
  },[loadedPoll,responseDataPoll]);


    const handleTPOChange = (event) => {
      console.log("TPO selected=",event.target);
      setSelectedTpo(event.target.value);
      props.tpoChange(Number(event.target.value));
      
  };
  
  const setNextTPO=()=>{
    console.log("next tpo, selectedtpoData=",selectedTpoData);
    let selectedIndex=-1;
    selectedTpoData.forEach((tpo,index)=>{
      if(tpo==selectedTpo){
        selectedIndex=index;
      }
    })
    if(selectedIndex!=selectedTpoData.length-1)
      selectedIndex=selectedIndex+1;
    else{
      // selectedIndex=0;
      return;
    }
    
      console.log("selected index val=",selectedIndex,selectedTpoData[selectedIndex])
      setSelectedTpo(selectedTpoData[selectedIndex]);
      props.tpoChange(Number(selectedTpoData[selectedIndex]));
  }

  const setPreviousTPO=()=>{
    let selectedIndex=-1;
    selectedTpoData.forEach((tpo,index)=>{
      if(tpo==selectedTpo){
        selectedIndex=index;
      }
    })
    if(selectedIndex!=0)
      selectedIndex=selectedIndex-1;
    else{
      // selectedIndex=selectedTpoData.length-1;
      return;
    }
      
    
    setSelectedTpo(selectedTpoData[selectedIndex]);
    props.tpoChange(Number(selectedTpoData[selectedIndex]));
  }

  // useEffect(()=>{
  //   console.log("searchText=", searchText );
  //   if(instrumentsData!=undefined){
  //     if(searchText=="")
  //     setOptions(instrumentsData)
  //     else{
  //       const temp=instrumentsData.filter((option) => containsText(option, searchText))
  //       setOptions(temp);
  //     }
  //   }
  // },[searchText])

  useEffect(()=>{
    console.log("searchText=", searchText );
    searchRef.current=searchText;
       const timeout = setTimeout(() => {
      if (selectRef.current) {
        // selectRef.current.scrollTo(0, 0);
        if(instrumentsData!=undefined && (searchRef.current==undefined ||  searchRef.current=="")){
          let index=instrumentsData.findIndex(item=>item.instr==selectedInstrument)
          if(index==-1)
          index=0
          selectRef.current.scrollTo({ index: index });
        }
        else selectRef.current.scrollTo({ index: 0 });
      }

    }, 100);
    return () => clearTimeout(timeout);
  },[searchText])

  useEffect(()=>{
    console.log("OFSETTINGS HEADER props.tpo useeffect=",props.tpo)
    //check if tpo is present with the tpo list
    // if(selectedTpoData.includes(props.tpo))
      setSelectedTpo(props.tpo);
    // else  
      // props.tpoChange(selectedTpo);
  },[props.tpo])

  //keep track of current selected tpo index to enable + - buttons for tpo
  useEffect(()=>{
    if(selectedTpo!=undefined && selectedTpoData)
    selectedTpoData.forEach((tpo,index)=>{
      if(tpo==selectedTpo){
       setSelectedTPOIndex(index);
      }
    })
  },[selectedTpo])

  //default MP chart view from bookmark back
  const goBackToMP=()=>{
    let index = instrumentsData.findIndex(item => item.name === userSettings.data.default_instrument);
    console.log("Header settings def inst index= ",index);
    if(index==-1)
    index=0;

    const symbol=instrumentsData[index].instr;
    props.loadDefaultMPChart();
    handleInstrumentChange(undefined,undefined,symbol);
   
    

  }

  const clearAutosave=()=>{
    props.clearAutosave();
  }

  useEffect(()=>{
    if(props.reloadInstrument!=undefined){
      handleInstrumentChange(undefined,undefined,props.reloadInstrument);

    }
  },[props.reloadInstrument])

  useEffect(()=>{
    console.log("selectedTimeFrame===",props.selectedTimeFrame)
  },[props.selectedTimeFrame])

  return (
    <ConfigProvider
      theme={{
        // algorithm: [theme.darkAlgorithm, theme.compactAlgorithm],
        components: {
          
          Select: {
            colorPrimary: '#0d203f',
            colorBgContainer:"#0000ff",
            optionActiveBg:"#22314E",
            colorText:"#FFFFFF",
            optionSelectedBg:"#35445E",
            optionSelectedColor:"#FFFFFF",
            selectorBg:"#2f5cdb",
            colorTextPlaceholder:"#ffffff",
            colorIcon:"#ff0000",
            colorTextDisabled:"#899499"
            // algorithm: true, // Enable algorithm
          },
          
        },
      }}
    >
   <div>
        <ToolbarFixed sx={{borderTop: `2px solid ${theme.palette.primaryTheme.shade03}`,borderRight: `2px solid ${theme.palette.primaryTheme.shade03}`,paddingLeft:0,paddingRight:4,left:props.chartStateData.left, width:props.chartStateData.width}}>
        {instrumentLoaded && options!=undefined?
        <Grid  container sx={{height:"100%"}} >
         
            <Grid item mobile={5} tablet={6} laptop={6} >
              {props.bookmarkData==undefined || props.bookmarkData.category==BOOKMARK_CATEGORY_AUTOSAVE?
              <Box display="flex"  justifyContent="center" alignContent="center" alignItems="center" sx={{height:"100%", borderRight:`1px solid ${theme.palette.primaryTheme.shade03}`}}>
            
                <FormControl variant="standard" size="small" sx={{ minWidth:"80%" }}>
         
                    {/* <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        value={searchText==""?selectedInstrument:searchText}
                        onChange={handleInstrumentChange}
                        onOpen={autoFocus}
                        label={selectedInstrument}
                        MenuProps={MenuProps}
                        onClose={() => setSearchText("")}
                        // This prevents rendering empty string in Select's value
                        // if search text would exclude currently selected option.
                        // renderValue={() => selectedInstrument}
                        >
                        
                        <ListSubheader>
                      
                            <TextField
                            size="small"
                            // Autofocus on textfield
                            autoFocus
                            // ref={focusUsernameInputField}
                            inputRef={inputRef}
                            variant='standard'
                            placeholder="Type to search..."
                            fullWidth
                            InputProps={{
                                startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                                )
                            }}
                            onChange={(e) => {setSearchText(e.target.value)
                            inputRef.current.focus();
                            }
                            }
                            onKeyDown={(e) => {
                              console.log("key down 1");
                                if (e.key !== "Escape") {
                                  console.log("key down 2");
                                // Prevents autoselecting item while typing (default Select behaviour)
                                e.stopPropagation();
                                }
                            }}
                            />
                        </ListSubheader>  
                        {options.map((item,index) => (
                          <MenuItem key={item.instr} value={item.instr} onKeyDown={(e) => e.stopPropagation()}>{item.name}</MenuItem>
                        ))}
                    </Select> */}

                    <Select
                        variant="borderless"
                        suffixIcon={<ArrowDropDownIcon  style={{ color: 'white' }} />}
                        ref={selectRef}
                        showSearch
                        style={{
                          // width: 200,
                          height:24,
                          maxHeight:24,
                          marginTop:-4,
                          color:"#FFFFFF"
                        }}
                       
                        placeholder="Search Instrument"
                        optionFilterProp="children"
                        defaultValue={"NIFTY"}
                        value={selectedInstrument}
                        dropdownStyle={{ backgroundColor: '#0d203f',color:'#00ff00',minWidth:"240px" }}
                        // onChange={handleSelectChange}
                        onChange={handleInstrumentChange}
                        onSearch={(value)=>setSearchText(value)}
                        // onDropdownVisibleChange={handleDropdownVisibleChange}
                        // filterOption={(input, option) => (option?.name ?? "").includes(input)}
                        // filterSort={(optionA, optionB) =>
                        //   (optionA?.children ?? '').toLowerCase().localeCompare((optionB?.children ?? '').toLowerCase())
                        // // console.log("option=== A B=",optionA,optionB,inputValue)
                        //   // optionA.children.toLowerCase().indexOf(inputValue.toLowerCase()) -
                        //   // optionB.children.toLowerCase().indexOf(inputValue.toLowerCase())
                        // }
                        filterOption={(input, option) => {
                          const words = input.trim().toLowerCase().split(' ');
                         return words.every(word => option.props.children.toLowerCase().includes(word));
                          
                          // return (
                          //   // option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                          //   option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          // );
                        }}
                      >
                      {options.map((item) => (
                        <Option key={item.instr} value={item.instr} disabled={props.disabled} style={{fontSize:"12px"}}>

                          {item.name}
                        </Option>
                      ))}
                </Select>      
                </FormControl>
              </Box>:
               <Box sx={{textAlign: 'center', display: 'flex', flexDirection: "row",alignItems:"center", justifyContent: "flex-start" ,height:"100%", borderRight:`1px solid ${theme.palette.primaryTheme.shade03}`}}>
                <Tooltip title="Back to Order Flow Chart">
            <IconButton
             sx={{
              marginLeft:3,
              width: 20,
              height: 20}}
              onClick={() => goBackToMP()}
              >
              <ArrowBackIcon sx={{ width: "24px", height: "24px" }} />
            </IconButton>
          </Tooltip>
              <Typography variant='lableTextSettings' sx={{ml:3}}>{props.bookmarkData.name}:&nbsp;({props.bookmarkData.data.instrument})</Typography>
              </Box>}
            </Grid>
             
            <Grid item mobile={7} tablet={6} laptop={6} >
              {!props.showTable?
            <Box display="flex"  justifyContent="space-between" alignContent="center" alignItems="center" sx={{width:"100%",overflowX:'auto',minWidth:"200px"}} >
            {/* <Box display="flex"  justifyContent="flex-start" alignContent="center" alignItems="center" >
              <Tooltip title={"Price per row"}>
                <Typography variant='lableTextSettings' sx={{fontSize:12,ml:2}}>{"PPR "}</Typography>
                </Tooltip>
                <FormControl variant="standard" size="small" sx={{ml:1, minWidth:"70px",height:"58%" }}>
                    <MUISelect
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        value={selectedTpo}
                        onChange={handleTPOChange}
                        label="TPO Size"
                        sx={{textAlign:"center",width:70,paddingTop:"4px"}}
                        MenuProps={MenuPropsTPO}
                        >
                        {selectedTpoData.map((item,index) => (
                            <MenuItem key={item+index} value={item}>{item}</MenuItem>
                        ))}
                    </MUISelect>
                </FormControl>
       
        <IconButton
            onClick={() => setPreviousTPO()}
            disabled={selectedTPOIndex==0}
            sx={{
              marginLeft:2,
              width: 20,
              height: 20,
              borderRadius: 0,
              border: "0.4px solid",
              borderColor: "primaryTheme.shade10"
            }}
            aria-label='add'>

            <RemoveIcon sx={{
              width: 20,
              height: 20,
            }}
            />
            </IconButton>
            <IconButton
           onClick={() => setNextTPO()}
           disabled={selectedTPOIndex==selectedTpoData.length-1}
            sx={{
              marginLeft:1,
              width: 20,
              height: 20,
              borderRadius: 0,
              border: "0.4px solid",
              borderColor: "primaryTheme.shade10"
            }}
            aria-label='add'>

            <AddIcon sx={{
              width: 20,
              height: 20,
            }}
            />
        </IconButton>
        
              </Box> */}
              
              </Box>:
              <></>
              } 
            </Grid>
            
         
                      
          </Grid>
          :<></>}
          
          
        </ToolbarFixed>
       
    </div>
    </ConfigProvider>
  )
}

export default CandlestickHeader
