import AbcIcon from "@mui/icons-material/Abc"
import CandlestickChartIcon from "@mui/icons-material/CandlestickChartOutlined"
import FormatAlignCenterIcon from "@mui/icons-material/FormatAlignCenter"
import ShowChartIcon from "@mui/icons-material/ShowChart"
import StackedLineChartIcon from "@mui/icons-material/StackedLineChart"
import {
  Avatar,
  Box,
  Button,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Select,
  Switch,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material"
import { styled, useTheme } from "@mui/material/styles"
import React, { useEffect, useState } from "react"
import {
  getChartOptionsData,
} from "../../../common/utility/configData"
import {
  OF_TIME_FRAME,
  PROFILE_VIEW,
  ROTATING_COT_LIST,
  TEXT_MSGS,
  USER_RESOURCES_LIST,
  USER_ROLE_FIELDS,
} from "../../../common/utility/constant"
import {
  CHART_TYPE,
  ROTATING_COT_VAL,
  VA_TYPE,
  VP_TYPE,
} from "../../../common/utility/mp_chart_constants"
// import RestartAltOutlinedIcon from '@mui/icons-material/RestartAltOutlined';
import MoreVertIcon from "@mui/icons-material/MoreVert"
import ScoreOutlinedIcon from "@mui/icons-material/ScoreOutlined"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem";
import SettingsIcon from '@mui/icons-material/Settings';
import { getTimeFrameForLineChart, isCOTChangeAllowed, isD3BasedLineChart } from "../../../common/utility/helperFunc"
import useAuth from "../../../common/hooks/useAuth"
import VisibilityIcon from '@mui/icons-material/Visibility';

import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const ITEM_HEIGHT = 48
const ITEM_HEIGHT_NEW = 26
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT_NEW * 10 + ITEM_PADDING_TOP,
      width: 120,
      autoFocus: false,
    },
  },
}

export const FooterToolbar = styled(Toolbar)(
  ({ theme }) => `
    background-color:${theme.palette.primaryTheme.shade01};
    width:100vw;
    height:32px;
    position:fixed;
    text-align:center;
    ${theme.breakpoints.up("mobile")}{
      min-height:32px;
    }
   `
)

/**
 *
 * @returns fixed toolbar for copyright text
 */
function LineChartFooterNew(props) {
  const theme = useTheme()
  const [userSettings, setUserSettings] = useState(props.userSettings)
  const [selectedInstrumentData, setSelectedInstrumentData] = useState(
    props.selectedInstrumentData
  )

  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const [chartOptionsData, setChartOptionsData] = useState(
    getChartOptionsData(props.auth)
  )
  const [isStocksAllowed, setIsStocksAllowed] = useState(
    props.auth.resources?.find((el) => el == USER_RESOURCES_LIST.OF_STOCKS)
      ? true
      : false
  )
  const [isIndexAllowed, setIsIndexAllowed] = useState(
    props.auth.resources?.find((el) => el == USER_RESOURCES_LIST.OF_INDEX)
      ? true
      : false
  )

  const [selectedTimeFrame,setSelectedTimeFrame]=useState(props.selectedInstrumentData.of?props.timeFrame:"1");
  const [timeFrames,setTimeFrames]=useState(getTimeFrameForLineChart(props.plotData.plot,props.plotData.doix,props.selectedInstrumentData.of));
  const [selectedCot,setSelectedCot]=useState(props.selectedCot);
  const [cotList,setCotList]=useState(ROTATING_COT_LIST);
  const { auth } = useAuth()
  const isPro2User=(auth.role.find(el=>el==USER_ROLE_FIELDS.PRO2))?true:false;
  const [panel1Visible,setPanel1Visible]=useState(props.panel1Visible);
 
 
  const handlePanel1Toggle = () =>{
    console.log("Panel1 test footer handleToggle val=",panel1Visible,!panel1Visible)
    props.changePanel1Visibility(!panel1Visible);
    setPanel1Visible((prev) => !prev);
    }

  useEffect(()=>{
    console.log("LCFooter props and TF=",props,props.timeFrame)
    if(!cotList.includes(selectedCot)){
      setCotList([selectedCot,...cotList]);
    }
  },[])

  useEffect(()=>{
    // console.log("Panel1 test footer useEffect val=",panel1Visible)
    // props.changePanel1Visibility(panel1Visible);
    setPanel1Visible(props.panel1Visible)
  },[props.panel1Visible])

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = (event, index) => {
    setAnchorEl(null)
    if (index >= 0) {
      console.log(
        "event.target.value menu=======>",
        event.target.value,
        chartOptionsData[index],
        index
      )
      props.handleChartChange(chartOptionsData[index])
    }
  }

  const openLineChart = () => {
    if (props.basicUser) props.openSubscribeModal()
    else props.openLineChart()
  }

  const openOFChart = () => {
    if (
      !isStocksAllowed &&
      selectedInstrumentData.resource &&
      selectedInstrumentData.resource == "stocks"
    ) {
      props.openSunscriptionModal(
        true,
        TEXT_MSGS.OF_CHART_STOCKS_RESOURCE_NOT_SUBSCRIBED_DESC
      )
      return
    } else if (
      !isIndexAllowed &&
      selectedInstrumentData.resource &&
      selectedInstrumentData.resource == "index"
    ) {
      props.openSunscriptionModal(
        true,
        TEXT_MSGS.OF_CHART_INDEX_RESOURCE_NOT_SUBSCRIBED_DESC
      )
      return
    } else props.openOFChart()
  }

  const openMPChart = () => {
    props.openMPChart()
  }

  useEffect(() => {
    console.log("StraddleTest LCContainer 4 00 Footer",props.selectedInstrumentData,props.timeFrame)
    setSelectedInstrumentData(props.selectedInstrumentData)
  }, [props.selectedInstrumentData])

  const openSettings=()=>{
    props.openSettings();
  }

  const changeTimeFrame=(val)=>{
    if(val!=selectedTimeFrame){
      setSelectedTimeFrame(val);
      props.changeTimeFrame(val);
    }
  }

  useEffect(()=>{
    console.log("StraddleTest LCContainer 4 Footer",selectedInstrumentData,props.selectedInstrumentData,props.timeFrame)
    setSelectedTimeFrame(props.selectedInstrumentData.of?props.timeFrame:"1");
  },[props.timeFrame])
  
useEffect(()=>{
  setTimeFrames(getTimeFrameForLineChart(props.plotData.plot,props.plotData.doix,props.selectedInstrumentData.of));
},[props.plotData,props.selectedInstrumentData])
  
useEffect(()=>{
  setSelectedCot(props.selectedCot);
},[props.selectedCot])

const handleCotChange = (event) => {
  // console.log("TPO selected=",event.target);
  setSelectedCot(event.target.value);
  props.cotChanged(Number(event.target.value));
  
};
  return (
    <>
      <FooterToolbar
        sx={{
          paddingLeft: "4px",
          paddingRight: "8px",
          borderTop: `1px solid ${theme.palette.primaryTheme.shade03}`,
          borderRight: `2px solid ${theme.palette.primaryTheme.shade03}`,
          left: props.chartStateData.left,
          top: props.chartStateData.top + props.chartStateData.height + 20,
          width: props.chartStateData.width,
        }}
      >
        <>
          <Box
            display="flex"
            sx={{
              height: "100%",
              overflowX: "auto",
              overflowY: "hidden",
              width: `calc(${props.chartStateData.width - 160}px)`,
              alignItems: "center",
            }}
          >
            {(props.chartType==CHART_TYPE.LINE_CHART || props.chartType==CHART_TYPE.BAR_CHART)&& selectedInstrumentData.of?
             <Tooltip title={props.chartType==CHART_TYPE.LINE_CHART?"Line Chart Settings":"Bar Chart Settings"}>
              <SettingsIcon onClick={openSettings}sx={{ml:"2px",marginRight:"6px", marginTop:props.chartType==CHART_TYPE.MARKET_PROFILE?"-3px":"-3px",cursor:'pointer'}}/>
          </Tooltip>:
          <></>
          }
{props.showTimeFrame && isD3BasedLineChart(props.plotData.plot,props.plotData.doix)?
<>
{timeFrames.filter(obj=>{
                return true;
                  // if(!props.basicUser)
                  //   return true;
                  // else
                  //   return obj.value!=OF_TIME_FRAME_VALUES.tf_15_min
                  // return props.selectedInstrumentData.tf.includes(obj.value) && obj.value!=TIME_FRAME_VALUES.yearly  //dont show yearly time frame for basic user
                  
            }).map((item,index) => (
                            <Tooltip title={item.displayText}>
                            <Button  variant="text" key={item.key} onClick={()=>changeTimeFrame(item.value)}
                            sx={{minWidth:50,fontSize:14,width:50,height:30,borderRadius:0,marginBottom:"8px",marginTop:1,
                            backgroundColor:selectedTimeFrame==item.value?"#567":"transparent",
                            "&:hover": {
                              backgroundColor: '#567'
                            }}}>
                            {item.value}
                            </Button>
                            </Tooltip>
                        ))}
              </>:
              <></>
              } 
              {/* {isPro2User && isCOTChangeAllowed(props.plotData.plot,props.plotData.doix)?
            <Box display="flex"  justifyContent="flex-start" alignContent="center" alignItems="center" >
                <Typography variant='lableTextSettings' sx={{fontSize:12,ml:2}}>{"nbCOT "}</Typography>
                <FormControl variant="standard" size="small" sx={{ml:1, minWidth:"70px",height:"58%" }}>
                    <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        value={selectedCot}
                        onChange={handleCotChange}
                        label="TPO Size"
                        sx={{textAlign:"center",width:50,paddingTop:"4px"}}
                        // MenuProps={MenuPropsTPO}
                        >
                        {cotList.map((item,index) => (
                            <MenuItem key={item} value={item}>{item}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                </Box>:
                <></>
                }          */}
            <Typography sx={{ fontSize: 14, ml: 2, mt: 0 }}>Charts</Typography>
            <IconButton
              aria-label="more"
              id="long-button"
              aria-controls={open ? "long-menu" : undefined}
              aria-expanded={open ? "true" : undefined}
              aria-haspopup="true"
              onClick={handleClick}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="long-menu"
              MenuListProps={{
                "aria-labelledby": "long-button",
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              PaperProps={{
                style: {
                  maxHeight: ITEM_HEIGHT * 4.5,
                  width: "20ch",
                },
              }}
            >
              {chartOptionsData.map((option, index) => (
                <MenuItem
                  key={option.key}
                  onClick={(event) => handleClose(event, index)}
                >
                  {option.key}
                </MenuItem>
              ))}
            </Menu>
            {/* {(props.plotData.plot=="fut"|| props.plotData.plot=="opt") && (props.plotData.doix!="1" || props.plotData.doix==false )?  */}
            {(props.plotData.plot=="fut"|| props.plotData.plot=="opt") && (props.plotData.doix!="1")? 
            <Tooltip title="Control Panel visibility and other options (Note these changes are not saved - to permanently save the settings, use the Settings section)">
            <Button
        variant="text"
        onClick={()=>handlePanel1Toggle()}
        endIcon={!panel1Visible ? <VisibilityIcon /> : <VisibilityIcon />}
      >
        {'Panel Controls'}
      </Button></Tooltip>:
      <></>
            }
            
          </Box>
        </>
        <Box
          display="flex"
          justifyContent="flex-end"
          sx={{ height: "100%", width: "170px", alignItems: "center" }}
        >
          {/* {selectedInstrumentData.line_chart ? (
           <Tooltip title={props.isEliteUser?"Candle Chart":"Line Chart"}>
              <ShowChartIcon
                onClick={openLineChart}
                sx={{
                  border: "0.5px solid",
                  ml: 1,
                  marginTop:
                    props.chartType == CHART_TYPE.MARKET_PROFILE
                      ? "-3px"
                      : "-3px",
                  height: 20,
                  width: 20,
                  cursor: "pointer",
                }}
              />
            </Tooltip>
          ) : (
            <></>
          )} */}
          {props.isEliteUser ?
            <>
              {selectedInstrumentData.cc ?
                <Tooltip title={"Candle Chart"}>
                  <ShowChartIcon onClick={openLineChart} sx={{ border: "0.5px solid", ml: 1, marginTop: props.chartType == CHART_TYPE.MARKET_PROFILE ? "-3px" : "-3px", height: 20, width: 20, cursor: 'pointer' }} />
                </Tooltip> :
                <></>
              }
            </> :
            <>
              {selectedInstrumentData.line_chart ?
                <Tooltip title={"Line Chart"}>
                  <ShowChartIcon onClick={openLineChart} sx={{ border: "0.5px solid", ml: 1, marginTop: props.chartType == CHART_TYPE.MARKET_PROFILE ? "-3px" : "-3px", height: 20, width: 20, cursor: 'pointer' }} />
                </Tooltip> :
                <></>
              }
            </>
          }
          {selectedInstrumentData.of ? (
            <Tooltip title="Orderflow Chart">
              <ScoreOutlinedIcon
                onClick={openOFChart}
                sx={{
                  border: "0.5px solid",
                  ml: 1,
                  marginTop:
                    props.chartType == CHART_TYPE.MARKET_PROFILE
                      ? "-3px"
                      : "-3px",
                  height: 20,
                  width: 20,
                  cursor: "pointer",
                }}
              />
            </Tooltip>
          ) : (
            <></>
          )}

          <Tooltip title="MP chart">
            <AbcIcon
              // disabled
              onClick={openMPChart}
              sx={{
                border: "0.5px solid",
                ml: 1,
                marginTop:
                  props.chartType == CHART_TYPE.MARKET_PROFILE
                    ? "-3px"
                    : "-3px",
                height: 20,
                width: 20,
                cursor: "pointer",
              }}
            ></AbcIcon>
          </Tooltip>
        </Box>
      </FooterToolbar>
    </>
  )
}

export default LineChartFooterNew

