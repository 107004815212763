import React,{memo, useContext,useEffect,useState,useRef} from 'react'
import { UserSettingsContext } from '../../../setup/routes-manager/RequireAuth'
import { useAxiosWithAuth } from '../../../common/api/hooks/useAxiosWithAuth';
import { URL } from '../../../common/api/urls';
import { useNavigate, useLocation } from 'react-router-dom';
import { TEXT_MSGS,SNACKBAR_AUTO_HIDE_DURATION,TABLE_TYPES,OF_ZOOM_TYPE } from '../../../common/utility/constant';
import { Snackbar,Alert,CircularProgress,InputLabel,Switch,Radio,RadioGroup,FormControlLabel,FormLabel, Link ,Checkbox,useTheme} from '@mui/material';
import {Grid, Box,Typography,Select,ListSubheader,TextField,InputAdornment,FormControl,MenuItem} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import SettingsFooter from './settings_footer';
import { findIndex } from '@amcharts/amcharts5/.internal/core/util/Array';
import { VP_TYPE,VA_TYPE } from '../../../common/utility/mp_chart_constants';
import InfoIcon from '@mui/icons-material/Info';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { createDictList, transformAndReverseMap } from '../../../common/utility/helperFunc';
import Modal from '@mui/material/Modal';

import { List } from 'react-virtualized';
import 'react-virtualized/styles.css'; // Import the styles for the List component
import useMediaQuery from '@mui/material/useMediaQuery';
import SettingsFooterPopup from './settings_footer_popup';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';


const style = {
    position: 'absolute',
    top: '5%',
    left: '10%',
    height: "90%",
    //   transform: 'translate(-50%, -50%)',
    width: "80%",
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    p: 0,
    overflow: 'scroll',
  };

const containsText = (text, searchText) =>{
  return text.name.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
}


 const ITEM_HEIGHT = 26;
 const ITEM_PADDING_TOP = 8;
 const MenuProps = {
   PaperProps: {
     style: {
       maxHeight: ITEM_HEIGHT * 10 + ITEM_PADDING_TOP,
       width: 80,
       autoFocus: false
     },
   },
 };

 const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 320,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));
const IndexSettings = (props) => {
  const from = "/login";
  const theme=useTheme();
  const [modalOpen, setModalOpen] = React.useState(props.open);
  const navigate = useNavigate(); 
  const [searchText, setSearchText] = React.useState("");
  const inputRef = React.useRef();
  const [options,setOptions]=useState();
  const { userSettings, setUserSettings } = useContext(UserSettingsContext);
  const [selectedInstrument, setSelectedInstrument] = React.useState(userSettings.data.default_instrument);
  const [showGlobalVolumeProfile,setShowGlobalVolumeProfile ] =useState(userSettings.data.show_global_vp);
  const [showAllVolumeProfile,setShowAllVolumeProfile ] =useState(userSettings.data.show_all_vp);
  const [showGlobalVolumeNumberProfile,setShowGlobalVolumeNumberProfile ] =useState(userSettings.data.show_global_vp_numbers);
  const [showAllVolumeNumber,setShowAllVolumeNumber ] =useState(userSettings.data.show_profile_vp_numbers);
  const [showUiNpocKrl,setShowUiNpocKrl ] =useState(userSettings.data.show_ui_npoc_krl!=undefined?userSettings.data.show_ui_npoc_krl:true);
  const [showKrl,setShowKrl ] =useState(userSettings.data.show_krl!=undefined?userSettings.data.show_krl:false);
  const [showCustomKrl,setShowCustomKrl ] =useState(userSettings.data.custom_krl_display!=undefined?userSettings.data.custom_krl_display:false);
  const [customKrlMultipleAnchor,setCustomKrlMultipleAnchor ] =useState(userSettings.data.custom_krl_overlapping_anchoring!=undefined?userSettings.data.custom_krl_overlapping_anchoring:false);
  const [anchorVwap2sdDisplay,setAnchorVwap2sdDisplay ] =useState(userSettings.data.show_2sd_band_custom_anchored_krl!=undefined?userSettings.data.show_2sd_band_custom_anchored_krl:false);
  const [showOFNPOC,setShowOFNPOC ] =useState(userSettings.data.show_ui_npoc_krl_of!=undefined?userSettings.data.show_ui_npoc_krl_of:true);
  const [showOFMetadata,setShowOFMetadata ] =useState(userSettings.data.show_metadata_marker_of!=undefined?userSettings.data.show_metadata_marker_of:true);
  const [vpType,setVPType]=useState(userSettings.data.vp_type)
  const [vaType,setVAType]=useState(userSettings.data.va_type)
  const [maxCompositeDays,setMaxCompositeDays]=useState(userSettings.data.max_ndays_for_composite)
  const [defaultLineChartNDays,setDefaultLineChartNDays]=useState(userSettings.data.default_lc_ndays)
  const [autosaveInterval,setAutosaveInterval]=useState(userSettings.data.autosave_interval)
  const [numberFormatLocale,setNumberFormatLocale]=useState(userSettings.data.number_format_locale)
  const [dataTypeContracts,setDataTypeContracts]=useState(userSettings.data.data_in_contracts);
  const [ordeflowZoomType,setOrdeflowZoomType]=useState(userSettings.data.of_zoom_type?userSettings.data.of_zoom_type:OF_ZOOM_TYPE.ASYMMETRIC)

  const autoFocus=()=>{
    const timeout = setTimeout(() => {
      if( inputRef.current)
      inputRef.current.focus();

    }, 100);

  }

  console.log(userSettings.data)

  const [uiDataState,setUIDataState]=useState({
    tpoGroupData:undefined,
    instrumentData:undefined,
    tpoGroups:undefined,
    isTPOLoaded:false,
    tableData:undefined
  })
  const {tpoGroupData,instrumentData,isTPOLoaded,tpoGroups,tableData}=uiDataState;

  const [settingsState,setSettingsState]=useState({
    settingsData:userSettings,
    isUserSettingsLoaded:userSettings!=undefined && userSettings.data!=undefined,
    apiTableData:userSettings.data.page_wise_hidden_columns  
  })
  const {settingsData,isUserSettingsLoaded,apiTableData}=settingsState;
  const [currentSettings,setCurrentSettings]=useState()
  
  const [msgState, setMsgState] = useState({
    open: false,
    msg:"" ,
    severity:"info"
  });
  
  const { open, msg, severity } = msgState;

  useEffect(() => {
    // document.title = "User Settings";
    // console.log("MP settings props instrument and data=",props.instrument,props.selectedInstrumentData)
  }, []);
  
  const handleModalClose = () => {
    // setOpen(false);
    props.cancelHandler();
  };
  
  const [cancel,responseData, error, loaded, reset,executeAPI ] = useAxiosWithAuth();
  const [cancelSettings,responseDataSettings, errorSettings, loadedSettings, resetSettings,executeAPISettings ] = useAxiosWithAuth();
  const [cancelDefault,responseDataDefault, errorDefault, loadedDefault, resetDefault,executeAPIDefault ] = useAxiosWithAuth();

  const parentRef = useRef(null);
  const [parentWidth, setParentWidth] = useState(null);
  
  useEffect(() => {
    // Function to update parentWidth
    const updateParentWidth = () => {
      if (parentRef.current) {
        const width = parentRef.current.offsetWidth;
        console.log("parent width useeffect=",width)
        setParentWidth(width);
      }
    };

    // Initial update
    updateParentWidth();

    // Attach event listener for window resize
    window.addEventListener('resize', updateParentWidth);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', updateParentWidth);
    };
  }, [parentRef.current]);

  const LargeList = ({ data }) => {
    const height=window.innerHeight-120;
    const isLaptop=useMediaQuery('(min-width:1024px)')
    const is760=useMediaQuery('(min-width:640px)')
    let width=0
    if( isLaptop){
      width=(window.innerWidth*8/12)-20;
    }else if(is760){
      width=Math.max((window.innerWidth*10/12)-20,760)-40;
      // width=760-40;
    }else{
      width=760-40;
    }
   

    const rowRenderer = ({ index, key, style }) => (
      <div key={data[index].name} style={style}>
          <Box display="flex" justifyContent="flex-start" sx={{mb:1,ml:4}}>
      <Typography sx={{mt:0,mr:5,fontSize:14,width:"140px"}}>{data[index].name}</Typography>
     
     <FormControl size="small" sx={{ width:"80px",minWidth:'80px',ml:2,mt:0 }}>
     <Select
       labelId="demo-simple-select-label"
       id="demo-simple-select"
       value={data[index].dailyTPO}
       MenuProps={MenuProps}
       sx={{height:"24px"}}
       onChange={(event)=>handleTPOChange(event,data[index],"daily")}
     >
       {data[index].tpo_list.map((tpo,index) => (
       <MenuItem value={tpo}>{tpo}</MenuItem>
       ))}
     </Select>
   </FormControl>
   <FormControl size="small" sx={{ width:"80px",minWidth:'80px',ml:2,mt:0 }}>
     <Select
       labelId="demo-simple-select-label"
       id="demo-simple-select"
       value={data[index].weeklyTPO}
       sx={{height:"24px"}}
       MenuProps={MenuProps}
       onChange={(event)=>handleTPOChange(event,data[index],"weekly")}
     >
       {data[index].tpo_list.map((tpo,index) => (
       <MenuItem value={tpo}>{tpo}</MenuItem>
       ))}
     </Select>
   </FormControl>
   <FormControl size="small" sx={{ width:"80px",minWidth:'80px',ml:2,mt:0 }}>
     <Select
       labelId="demo-simple-select-label"
       id="demo-simple-select"
       value={data[index].monthlyTPO}
       sx={{height:"24px"}}
       MenuProps={MenuProps}
       onChange={(event)=>handleTPOChange(event,data[index],"monthly")}
     >
       {data[index].tpo_list.map((tpo,index) => (
       <MenuItem value={tpo}>{tpo}</MenuItem>
       ))}
     </Select>
   </FormControl>
   <FormControl size="small" sx={{ width:"80px",minWidth:'80px',ml:2,mt:0 }}>
     <Select
       labelId="demo-simple-select-label"
       id="demo-simple-select"
       value={data[index].yearlyTPO}
       sx={{height:"24px"}}
       MenuProps={MenuProps}
       onChange={(event)=>handleTPOChange(event,data[index],"yearly")}
     >
       {data[index].tpo_list.map((tpo,index) => (
       <MenuItem value={tpo}>{tpo}</MenuItem>
       ))}
     </Select>
   </FormControl>
   </Box>
      </div>
    );
  
    console.log("width height=",width,height)
    return (
      <List
      width={width}
      height={height}
      rowCount={data.length}
      rowHeight={40}
      rowRenderer={rowRenderer}
      // autoHeight
      overscanRowCount={10}
      containerProps={{
        overflow: 'visible',
      }}
    />

    );
  };

  
  

/**
 * API response handler  
 */  
   useEffect(() => {
    if(loadedDefault){
      if(responseDataDefault!=null){
          
          setSelectedInstrument(responseDataDefault.data.default_instrument);
          const tpoData=processTPOData(tpoGroups,responseDataDefault);
          console.log("MP Settings : user setting response=",responseDataDefault,tpoData)
          setUIDataState((prevState)=>({
            ...prevState,
            tpoGroupData:tpoData    
          }));
        
          setSettingsState({settingsData:responseDataDefault,isUserSettingsLoaded:true,apiTableData:responseDataDefault.data.page_wise_hidden_columns});
      }
      else if(errorDefault!==null){
        setSettingsState((prevState)=>({
          ...prevState,
          isUserSettingsLoaded:true
        }))
        console.log("Error data=",errorDefault);
        setMsgState({open:true,msg:errorDefault?.response?.data?.message ?? `${TEXT_MSGS.NETWORK_ERROR_MSG}`,severity:"info"});
        //if unauthorized then redirec it to login page
        if(errorDefault?.response?.status === 401 || errorDefault?.response?.status === 403){
          console.log("status received =",errorDefault?.response?.status)
          navigate(from, { replace: true });
        }
    }
    resetDefault();
    }
  },[loadedDefault,responseDataDefault]);

  /**
 * API response handler  for user settings save
 */  
   useEffect(() => {
    if(loadedSettings){
    
      if(responseDataSettings!=null){
        setSettingsState((prevState)=>({
            ...prevState,
            settingsData:responseDataSettings
          }));
          setUserSettings(responseDataSettings);
          console.log("Save Settings : user setting response=",responseDataSettings,currentSettings)
          setMsgState({open:true,msg:responseDataSettings?.message ?? `${TEXT_MSGS.USER_SETTINGS_SAVED}`,severity:"success"});
          props.saveHandler(responseDataSettings);
       
      }
      else if(errorSettings!==null){
        console.log("Error data=",errorSettings);
        setMsgState({open:true,msg:errorSettings?.response?.data?.message ?? `${TEXT_MSGS.NETWORK_ERROR_MSG}`,severity:"info"});
        //if unauthorized then redirect it to login page
        if(errorSettings?.response?.status === 401 || errorSettings?.response?.status === 403){
          console.log("status received =",errorSettings?.response?.status)
          navigate(from, { replace: true });
        }
    }
    resetSettings();
    }
  },[loadedSettings,responseDataSettings]);


    //execute the data fetch on the component mount
    useEffect(()=>{
      let data={
        "symbol": "NIFTY",
        "instruments": 1,
        // "tpoList": 1,
        // "snakechart_data": 1
        "user_settings_form" : 1
      }
      executeAPI(URL.MPCHART_UIDATA,"POST",data); 
    },[]);
  
    const processTPOData=(data,userSettings)=>{
      let processedData=[];
      for (const key in data) {
        // console.log(key, data[key])
        let temp=data[key];
        temp.key=key;
        //  temp.name=key;
        temp.name=key.replace(/_OPT/,' OPTIONS');
        // console.log("daily tpo in settings =",userSettings.data.tf_tpo_map[key].daily);
        if(key in userSettings.data.tf_tpo_map){
          temp.dailyTPO=userSettings.data.tf_tpo_map[key].daily
          temp.weeklyTPO=userSettings.data.tf_tpo_map[key].weekly
          temp.monthlyTPO=userSettings.data.tf_tpo_map[key].monthly
          temp.yearlyTPO=userSettings.data.tf_tpo_map[key].yearly
          processedData.push(temp);
        } else {
          temp.dailyTPO=data[key].default_tpo;
          temp.weeklyTPO=data[key].default_tpo*data[key].tf_factor.weekly;
          temp.monthlyTPO=data[key].default_tpo*data[key].tf_factor.monthly;
          temp.yearlyTPO=data[key].default_tpo*data[key].tf_factor.yearly;
          processedData.push(temp);
        }

      }
      return processedData;
    }

    /**
   * API response handler  
   */  
     useEffect(() => {
      if(loaded){
        if(responseData!=null){
          responseData.instruments = responseData.instruments.map((item) =>
            transformAndReverseMap(item)
          );
            console.log("MPCHart UI Data response=",responseData)
          const tpoData=processTPOData(responseData.tpo_groups,userSettings);
          console.log("MP Settings 2 MPCHart UI Data response=",responseData,tpoData)
           setUIDataState({tpoGroupData:tpoData,tpoGroups:responseData.tpo_groups,instrumentData:responseData.instruments, isTPOLoaded:true,tableData:responseData.user_settings_form.page_wise_columns});
           setOptions (responseData.instruments);
        }
        else if(error!==null){
          setUIDataState({tpoGroupData:undefined,tpoGroups:undefined,instrumentData:undefined,isTPOLoaded:true,tableData:undefined});
          console.log("Error data=",error);
          setMsgState({open:true,msg:error?.response?.data?.message ?? `${TEXT_MSGS.NETWORK_ERROR_MSG}`,severity:"info"});
          //if unauthorized then redirec it to login page
          if(error?.response?.status === 401 || error?.response?.status === 403){
            console.log("status received =",error?.response?.status)
            navigate(from, { replace: true });
          }
      }
      reset();
      }
    },[loaded,responseData]);
    
    useEffect(()=>{
      console.log("searchText=", searchText );
      if(instrumentData!=undefined){
        if(searchText=="")
        setOptions(instrumentData)
        else{
          const temp=instrumentData.filter((option) => containsText(option, searchText))
          setOptions(temp);
        }
      }
    },[searchText])
  
    const handleInstrumentChange = (event) => {
      setSelectedInstrument(event.target.value);
    }  

    const handleVPChange = (event) => {
      setVPType(event.target.value);
    };

    const handleVAChange = (event) => {
      setVAType(event.target.value);
    };

    const handleLocaleChange = (event) => {
      setNumberFormatLocale(event.target.value);
    };

    const handleDataTypeChange = (event) => {
      console.log(event.target.value);
      setDataTypeContracts(event.target.value==="true");
    };

    const handleOFZoomChange = (event) => {
      setOrdeflowZoomType(event.target.value);
    };

    const handleTPOChange=(event,data,type)=>{
      let newData=JSON.parse(JSON.stringify(tpoGroupData));
      let index=newData.findIndex(item=>item.key==data.key)
      if(index==-1)
      return;

      if(type=="daily"){
        newData[index].dailyTPO=event.target.value;
      }
      else if(type=="weekly"){
        newData[index].weeklyTPO=event.target.value;
      }
      else if(type=="monthly"){
        newData[index].monthlyTPO=event.target.value;
      }
      else if(type=="yearly"){
        newData[index].yearlyTPO=event.target.value;
      }
      setUIDataState((prevState) => ({
        ...prevState,
       tpoGroupData:newData
      }));
      
    }

    const disableAutosave=()=>{
      setAutosaveInterval(0);
    }

    const saveSettings=()=>{
        // let newData=JSON.parse(JSON.stringify(userSettings));
        let newData={};
        newData.data={};
        newData.data.page_wise_hidden_columns=userSettings.data.page_wise_hidden_columns;
       
        newData.data.number_format_locale=numberFormatLocale;
        newData.data.data_in_contracts=dataTypeContracts;
    newData.data.page_wise_hidden_columns.INDEX_COMPONENTS=apiTableData.INDEX_COMPONENTS;
    newData.data.page_wise_hidden_columns.INDEX_FUT_COMPONENTS=apiTableData.INDEX_FUT_COMPONENTS;
    
   

      
      setCurrentSettings(newData);
      console.log("Saved setting=",newData,apiTableData);

      let data={"data":newData};

      executeAPISettings(URL.UPDATE_USER_SETTING,"POST",newData.data);

      
     
    }
    useEffect(()=>{
      setSelectedInstrument(settingsData.data.default_instrument);
      setShowGlobalVolumeProfile(settingsData.data.show_global_vp);
      setShowAllVolumeProfile(settingsData.data.show_all_vp);
      setShowGlobalVolumeNumberProfile(settingsData.data.show_global_vp_numbers);
      setShowAllVolumeNumber(settingsData.data.show_profile_vp_numbers);
      setShowUiNpocKrl(settingsData.data.show_ui_npoc_krl);
      setShowKrl(settingsData.data.show_krl);
      setShowCustomKrl(settingsData.data.custom_krl_display);
      setCustomKrlMultipleAnchor(settingsData.data.custom_krl_overlapping_anchoring);
      setAnchorVwap2sdDisplay(settingsData.data.show_2sd_band_custom_anchored_krl);
      setShowOFMetadata(settingsData.data.show_metadata_marker_of)
      setShowOFNPOC(settingsData.data.show_ui_npoc_krl_of);
      setOrdeflowZoomType(settingsData.data.of_zoom_type?settingsData.data.of_zoom_type:OF_ZOOM_TYPE.ASYMMETRIC);
      setVPType(settingsData.data.vp_type)
      setVAType(settingsData.data.va_type)
      setOptions (instrumentData);
      setMaxCompositeDays(settingsData.data.max_ndays_for_composite);
      setDefaultLineChartNDays(settingsData.data.default_lc_ndays);
      setAutosaveInterval(settingsData.data.autosave_interval);
      setNumberFormatLocale(settingsData.data.number_format_locale);
      setDataTypeContracts(settingsData.data.data_in_contracts);
      
    },[settingsData])

    const discardChanges=()=>{
      setUIDataState((prevState) => ({
        ...prevState,
       isTPOLoaded:false,
      }));
      setOptions (undefined);
      setSelectedInstrument(settingsData.data.default_instrument);
      setShowGlobalVolumeProfile(settingsData.data.show_global_vp);
      setShowAllVolumeProfile(settingsData.data.show_all_vp);
      setShowGlobalVolumeNumberProfile(settingsData.data.show_global_vp_numbers);
      setShowAllVolumeNumber(settingsData.data.show_profile_vp_numbers);
      setShowUiNpocKrl(settingsData.data.show_ui_npoc_krl);
      setShowKrl(settingsData.data.show_krl);
      setShowCustomKrl(settingsData.data.custom_krl_display);
      setCustomKrlMultipleAnchor(settingsData.data.custom_krl_overlapping_anchoring);
      setAnchorVwap2sdDisplay(settingsData.data.show_2sd_band_custom_anchored_krl);
      setShowOFMetadata(settingsData.data.show_metadata_marker_of)
      setShowOFNPOC(settingsData.data.show_ui_npoc_krl_of);
      setOrdeflowZoomType(settingsData.data.of_zoom_type?settingsData.data.of_zoom_type:OF_ZOOM_TYPE.ASYMMETRIC);
      setVPType(settingsData.data.vp_type)
      setVAType(settingsData.data.va_type)
      setOptions (instrumentData);
      setMaxCompositeDays(settingsData.data.max_ndays_for_composite);
      setDefaultLineChartNDays(settingsData.data.default_lc_ndays);
      setAutosaveInterval(settingsData.data.autosave_interval);
      setNumberFormatLocale(settingsData.data.number_format_locale);
      setDataTypeContracts(settingsData.data.data_in_contracts);
      setSettingsState((prevState)=>({
        ...prevState,
        apiTableData:settingsData.data.page_wise_hidden_columns  
      }))
     
      const tpoData=processTPOData(tpoGroups,settingsData);
      // console.log(tpoData)
      setUIDataState({tpoGroupData:tpoData,tpoGroups:tpoGroups,instrumentData:instrumentData, isTPOLoaded:true,tableData:tableData});
      
    }

    const resetToDefault=()=>{
      setSettingsState((prevState)=>({
        ...prevState,
        isUserSettingsLoaded:false
      }))
      // /api/user/settings?reset=true
      executeAPIDefault(`${URL.USER_SETTING}?reset=true`,"GET",{});

    }

/**
 * autoclosing of the snackbar msg bar 
 */ 
   const handleClose = (event, reason) => {
    setMsgState({...msgState,open:false});
};

  const handleColumnChange=(event,col,type)=>{
    if(event.target.checked){
      console.log("col,type",col,type)
      let data=JSON.parse(JSON.stringify(apiTableData));
      if(!(type in data)) data[type]=[];
      // console.log(data);
      data[type].push(col);
      // console.log(data[type]);
      setSettingsState((prevState)=>({
        ...prevState,
        apiTableData:data
      }));
    }else{
      let data=JSON.parse(JSON.stringify(apiTableData));
      data[type] = data[type].filter(item => item !== col)
      console.log(data[type]);
      setSettingsState((prevState)=>({
        ...prevState,
        apiTableData:data
      }));
    }
  }


  return (
    <Modal open={modalOpen} onClose={handleModalClose}>
    <>
    {isTPOLoaded && isUserSettingsLoaded && options!=undefined?
     <Box sx={style} >
         <CancelOutlinedIcon
                onClick={() => handleModalClose()}
                sx={{
                ml: 0, mt: 0,top: `calc(5% - 12px)`,left: `calc(90% - 13px)`,
                 height: 24, width: 26, cursor: 'pointer', position: 'fixed', zIndex: 9999
                }}
            />
    <Grid container spacing={2}>
    <Grid item mobile={0} tablet={1} laptop={2}>
     
    </Grid>
    <Grid item mobile={12} tablet={10} laptop={8} sx={{minWidth:"760px",ml:2,mr:2}}>
    <Box sx={{display:"flex",mr:1,mt:2}}><Typography sx={{mt:2,fontSize:24,fontWeight:'bold'}}>Index Table - Global Settings</Typography>
      <HtmlTooltip
            title={
              <React.Fragment>
                <Typography color="inherit">Settings specific to Index Table applicable across all instruments</Typography>
              </React.Fragment>
            }
          >
          <InfoIcon sx={{ml:1,marginTop:"20px"}}></InfoIcon>
        </HtmlTooltip>
      </Box>
   

    {/* <Box sx={{backgroundColor:'primaryTheme.shade03',zIndex:999,mt:2,borderRadius:5,height:"100px",width:"100%"}}> */}
    <Box sx={{backgroundColor:'primaryTheme.shade03',mt:2,pb:2,mb:5,pr:2,borderRadius:5,width:"100%"}}>
      <FormControl variant="standard" size="small" sx={{ minWidth:"80%",ml:2,mt:1 }}>
          <Box display="flex" justifyContent="flex-start" flexDirection='column' sx={{mt:1,ml:2}}>
          <Typography sx={{marginTop:"4px",mr:5,fontSize:16,mb:1}}>Index Table (Select the columns you want to hide and Save the settings)</Typography>
          {tableData.INDEX_COMPONENTS.map((col,index) => (
           <>
            <Box display="flex" justifyContent="flex-start" flexDirection='row' sx={{mt:0,ml:0}}>
              <Checkbox
                value={col}
                onChange={(event)=>handleColumnChange(event,col,TABLE_TYPES.INDEX_COMPONENTS)}
                checked={apiTableData.INDEX_COMPONENTS.includes(col.toString())}
                iconStyle={{
                  fill: '#ffb400', //hex color values (yellow)
                }}
              />
              <Typography sx={{mr:1,marginTop:"10px"}}>{col}</Typography>
              </Box>
              </>
            
          ))}             
        </Box>
        <Box display="flex" justifyContent="flex-start" flexDirection='column' sx={{mt:1,ml:2}}>
          
          {tableData.INDEX_FUT_COMPONENTS.length > 0 
          ? <>
            <Typography sx={{marginTop:"4px",mr:5,fontSize:16,mb:1}}>FUT (Futures) Index Table (Select the columns you want to hide and Save the settings)</Typography>
            {tableData.INDEX_FUT_COMPONENTS.map((col,index) => (
            <>
              <Box display="flex" justifyContent="flex-start" flexDirection='row' sx={{mt:0,ml:0}}>
                <Checkbox
                  value={col}
                  onChange={(event)=>handleColumnChange(event,col,TABLE_TYPES.INDEX_FUT_COMPONENTS)}
                  checked={apiTableData.INDEX_FUT_COMPONENTS?.includes(col.toString())}
                  iconStyle={{
                    fill: '#ffb400', //hex color values (yellow)
                  }}
                />
                <Typography sx={{mr:1,marginTop:"10px"}}>{col}</Typography>
                </Box>
                </>
              
            ))}
            </>
          : <></>}
        </Box>
  
      </FormControl>
      </Box>

      <Box sx={{display:"flex",mr:1,mt:0}}><Typography sx={{mt:2,fontSize:24,fontWeight:'bold'}}>Global Settings</Typography>
    <HtmlTooltip
          title={
            <React.Fragment>
              <Typography color="inherit">Global settings applicable across all charts</Typography>
              {/* <em>{"And here's"}</em> <b>{'some'}</b> <u>{'amazing content'}</u>.{' '}
              {"It's very engaging. Right?"} */}
            </React.Fragment>
          }
        >
        <InfoIcon sx={{ml:1,marginTop:"20px"}}></InfoIcon>
      </HtmlTooltip>
    </Box>
    <Box sx={{backgroundColor:'primaryTheme.shade03',mt:2,pb:2,mb:6,pr:2,borderRadius:5,width:"100%"}}>
    
    <Box display="flex" justifyContent="flex-start" sx={{mb:1,ml:4,width:"100%",marginTop:"0px"}}>
        <Box sx={{width:"272px",display:"flex"}}>
          <Typography sx={{mr:1,mt:2,fontSize:14}}>Locale for Number Formatting</Typography>
          <HtmlTooltip
                  title={
                    <React.Fragment>
                      <Typography color="inherit">Locale used for formatting large numbers across all pages</Typography>
                      Indian locale formats the numbers in Lakhs, Crores format. <br/>
                      US locale formats data in millions and billions
                    </React.Fragment>
                  }
                >
              <InfoIcon sx={{marginTop:"16px"}}></InfoIcon>
            </HtmlTooltip>
        </Box>
        <FormControlLabel value="en-In" 
          control={<Radio checked={numberFormatLocale === 'en-In'} onChange={handleLocaleChange} size="small"  sx={{mt:1}}/>} 
          label={<Typography sx={{fontSize:14, mt:1}}>Indian Format</Typography>} />
          <FormControlLabel value="en-Us" 
          control={<Radio checked={numberFormatLocale === 'en-Us'} onChange={handleLocaleChange} size="small" sx={{mt:1}} />} 
          label={<Typography sx={{fontSize:14, mt:1}}>US Format</Typography>} />
        
      </Box>
      <Box display="flex" justifyContent="flex-start" sx={{mb:1,ml:4,width:"100%",marginTop:"2px"}}>
        <Box sx={{width:"272px",display:"flex"}}>
          <Typography sx={{mr:1,mt:1,fontSize:14}}>OI and Vol Data</Typography>
          <HtmlTooltip
                  title={
                    <React.Fragment>
                      <Typography color="inherit">OI and Volume data across all pages should be displayed in #Contracts vs Total value?</Typography>
                    </React.Fragment>
                  }
                >
              <InfoIcon sx={{marginTop:"6px"}}></InfoIcon>
            </HtmlTooltip>
        </Box>
        <FormControlLabel value="true" 
          control={<Radio checked={dataTypeContracts} onChange={handleDataTypeChange} size="small" />} 
          label={<Typography sx={{fontSize:14}}>Contracts</Typography>} />
          <FormControlLabel value="false" 
          control={<Radio checked={!dataTypeContracts} onChange={handleDataTypeChange} size="small" />} 
          label={<Typography sx={{fontSize:14}}>Total Value</Typography>} />
        
      </Box>
   
    </Box>
    
    </Grid>
    <Grid item mobile={0} tablet={1} laptop={2}>
     
    </Grid>
    
   
</Grid>
<SettingsFooterPopup saveSettings={saveSettings} discardChanges={discardChanges} resetDefault={resetToDefault}>

</SettingsFooterPopup>
</Box>    :
<>
<CircularProgress sx={{marginTop:"20%",marginLeft:"45%"}}/></>}
        <Snackbar onClose={handleClose} anchorOrigin={{vertical: 'top',horizontal: 'center'}} sx={{top:"48px"}} open={open} autoHideDuration={SNACKBAR_AUTO_HIDE_DURATION} >
          <Alert  severity={severity} sx={{ width:{mobile: '80%',tablet:"70%",laptop:"40%" }}}>
            {msg}
          </Alert>  
       </Snackbar>
       </>
       </Modal>
  )
}

export default IndexSettings
   